<template>
  <v-row dense>
    <v-col cols="12" :md="preview !== false ? '6' : '12'">
      <v-tabs v-model="tabModel" vertical show-arrows>
        <v-tab aut-category-tab="attributes">Attributes</v-tab>
        <v-tab
          v-for="category in classCategories"
          :key="category.id"
          :aut-category-tab="category.id"
        >
          <div class="d-flex">
            <div>{{ category.label }}</div>
            <ToolTip v-if="category.tooltip" :content="category.tooltip" />
          </div>
        </v-tab>

        <v-tab aut-category-tab="classes">Classes</v-tab>
        <v-tab-item aut-category-tab-item="attributes">
          <v-container>
            <v-row dense>
              <v-col>
                <div class="smaller pa-2">
                  <v-text-field
                    hide-details
                    dense
                    prepend-icon="mdi-magnify"
                    aut-role-search
                    placeholder="Search"
                    clearable
                    v-model="searchCriteria"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row
              dense
              class="flex-wrap"
              style="max-height: 70vh; overflow-y: scroll"
            >
              <v-col
                cols="12"
                v-for="(attribute, index) in filteredAttributes"
                :key="attribute.name"
              >
                <AttributeControl
                  @change="handleAttributeChange(attribute, index, $event)"
                  :control="attribute"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item
          v-for="category in classCategories"
          :key="category.id"
          :aut-category-tab-item="category.id"
        >
          <ClassControl
            v-if="fieldDefinition"
            :category="category"
            :field="fieldDefinition"
            @update="updateClasses"
          />
        </v-tab-item>

        <v-tab-item aut-category-tab-item="classes">
          <v-row>
            <v-col cols="12">
              <ClassesControl :field="field" @update="updateClasses" />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-col cols="6" v-if="preview !== false" class="pt-10">
      <v-card class="transparent behaviour_centered">
        <v-card-title> Preview </v-card-title>
        <v-card-text>
          <div class="d-flex pa-2" justify-center>
            <v-spacer />
            <field-preview
              v-if="fieldDefinition"
              :definition="fieldDefinition"
            />
            <v-spacer />
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { sortBy, defaultsDeep } from "lodash";
import AttributeControl from "./AttributeControl.vue";

import { clone, convertToFlatObject, convertToNestedObject } from "@/util.js";
import ClassesControl from "./ClassesControl.vue";
import ClassControl from "./ClassControl.vue";
import { CATEGORIES } from "./util";

const debug = require("debug")("atman.field_settings"); // eslint-disable-line

export default {
  name: "FieldSettings",
  components: {
    AttributeControl,
    FieldPreview: () => import("@/components/fields/Field/FieldPreview.vue"),
    ClassesControl,
    ClassControl,
  },
  data() {
    const activeField = clone(this.field);
    this.addAttributeValues(this?.definition?.display?.attributes, activeField);
    return {
      tabModel: 0,
      renderKey: 1,
      activeField,
      fieldContext: "",
      fieldDefinition: null,
      searchCriteria: null,
    };
  },
  props: {
    field: {
      type: Object,
    },
    preview: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    definition: {
      type: Object,
    },
  },

  computed: {
    filteredAttributes() {
      let result = [];
      if (!this.searchCriteria) {
        result = this.allAttributes;
      } else {
        result = this.allAttributes.filter((feature) => {
          return (
            JSON.stringify(feature)
              .toLowerCase()
              .indexOf(this.searchCriteria.toLowerCase()) != -1
          );
        });
      }
      debug(`filteredFeatureList`, result);
      return result;
    },
    allAttributes() {
      let result = (this.activeField.attributes || []).filter(
        (item) => !item.hide_in_designer
      );
      return result;
    },
  },
  created() {
    this.classCategories = sortBy(CATEGORIES, ["label"]);
  },
  async mounted() {
    const component = this;
    component.renderField();
  },
  methods: {
    updateClasses(classes) {
      const definition = clone(this.fieldDefinition);
      definition.display.classes = classes;
      this.activeField.classes = classes;
      this.$emit("change", this.activeField);

      this.$set(this, "fieldDefinition", definition);
      this.$emit("update:definition", definition);
      this.renderField(definition);
    },
    handleAttributeChange(attribute, index, updatedValue) {
      attribute.value = updatedValue;
      this.activeField.attributes.splice(index, 1, attribute);
      let updatedAttributes = {
        display: {
          attributes: {
            [attribute.name]: updatedValue,
          },
        },
      };
      updatedAttributes = convertToNestedObject(updatedAttributes);

      const fieldDefinition = defaultsDeep(
        updatedAttributes,
        convertToNestedObject(this.fieldDefinition)
      );
      const definition = convertToNestedObject(clone(fieldDefinition));
      this.$set(this, "fieldDefinition", definition);
      this.$emit("change", this.activeField);
      this.$emit("update:definition", definition);
      this.renderField(definition);
    },
    renderField(definition) {
      const field = this.activeField;
      if (!field) {
        return null;
      }

      const attributes = {};
      (field.attributes || []).forEach((attribute) => {
        attributes[attribute.name] = attribute.value;
      });
      const result = defaultsDeep({}, definition || this.definition, {
        display: {
          attributes,
          classes: field.classes || [],
        },
      });
      debug(`in render Field`, result);
      this.fieldDefinition = null;
      this.$nextTick(() => {
        this.fieldDefinition = result;
      });
    },

    getAttributeValueFromStore(type, attribute) {
      const currentValueInStore = this.$store.getters[
        "preferences/fieldAttributeValue"
      ](type, attribute.name);
      return typeof currentValueInStore != "undefined"
        ? currentValueInStore
        : attribute.value;
    },
    addAttributeValues(runtimeAttributes = {}, field) {
      let effectiveAttributes = convertToFlatObject(runtimeAttributes);
      debug(`effectiveAttributes`, runtimeAttributes, effectiveAttributes);
      (field.attributes || []).forEach((attribute) => {
        let attributeValue = effectiveAttributes[attribute.name];
        if (typeof attributeValue != "undefined") {
          attribute.value = attributeValue;
          return;
        }
        attribute.value = this.getAttributeValueFromStore(
          field.type,
          attribute
        );
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-tabs-bar__content {
    width: 90px;
  }
  .v-tab {
    font-size: 0.65em !important;
  }
}
</style>
