export default {
  label: "Array",
  filters: ["form_editor", "list_editor"],
  type: "array",
  palette: false,
  description: "Encapsulates an Array",
  templates: [
    {
      id: "field_with_string",
      description: "The default Array field",
      label: "Field with string value",
      variables: {
        data: {
          default: "lorem",
        },
      },
      value: {
        display: {
          attributes: {
            "remove-btn": true,
            "btn-color": "primary",
            "add-btn": true,
          },
        },
        name: "default",
        label: "Array Title",
        type: "array",
        mode: "input",
        field: {
          label: "Title",
          type: "text",
          default_value: "",
        },
      },
    },
    {
      id: "default",
      description: "The default Array field",
      label: "Default",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        display: {
          attributes: {
            "remove-btn": true,
            "btn-color": "primary",
            "add-btn": true,
          },
        },
        name: "default",
        label: "Array Title",
        type: "array",
        mode: "input",
        field: {
          label: "Title",
          type: "text",
          default_value: "",
        },
      },
    },
    {
      id: "object_array_field_display",
      description: "The object Array field",
      label: "Object Display",
      variables: {
        data: {
          object_array_field_display: [
            {
              address_line_1: "lorem",
              address_line_2: "ipsum",
              address_line_3: "gorem",
            },
          ],
        },
      },
      value: {
        display: {
          attributes: {
            "remove-btn": true,
            "btn-color": "primary",
            "add-btn": true,
          },
        },
        name: "object_array_field_display",
        label: "Array Title",
        type: "array",
        mode: "display",
        field: {
          label: "Title",
          type: "object",
          fields: [
            {
              name: "address_line_1",
              label: "Line 2",
              type: "text",
              mode: "display",
              display: {
                width: "full",
              },
            },
            {
              name: "address_line_2",
              label: "Line 2",
              type: "text",
              mode: "display",
              display: {
                width: "full",
              },
            },
            {
              name: "address_line_3",
              label: "Line 2",
              type: "text",
              mode: "display",
              display: {
                width: "full",
              },
            },
          ],
          default_value: "",
        },
      },
    },
    {
      id: "display",
      description: "Display Mode",
      label: "Display Mode",
      variables: {
        data: {
          display: ["test1", "test2"],
        },
      },
      value: {
        name: "display",
        label: "Array Title",
        type: "array",
        mode: "display",
        field: {
          label: "Title",
          type: "text",
          default_value: "",
        },
      },
    },
    {
      id: "features",
      description: "The features Array field",
      label: "Features",
      variables: {
        data: {
          features: null,
        },
      },
      value: {
        display: {
          attributes: {
            "remove-btn": true,
            "btn-color": "primary",
            "add-btn": true,
          },
        },
        name: "features",
        label: "Features",
        type: "array",
        mode: "input",
        field: {
          label: "Feature",
          type: "feature",
          default_value: {
            name: "",
            description: "",
            constraint: {
              type: "",
              value: "",
              units: "",
            },
          },
        },
      },
    },
    {
      id: "proposal_detail",
      description: "Array field with computation",
      label: "Array field with computation",
      variables: {
        data: {
          proposal_detail: null,
        },
      },
      value: {
        _order: 11,
        display: {
          attributes: {
            "remove-btn": true,
            "btn-color": "primary",
            "add-btn": true,
          },
          block: false,
          classes: ["primary--text", "py-3", "text-button"],
          width: "12",
        },
        field: {
          default_value: "",
          display: {
            width: "10",
          },
          fields: [
            {
              display: {
                width: "1",
              },
              label: "SNo.",
              name: "sno",
              type: "text",
            },
            {
              display: {
                width: "4",
              },
              label: "Item",
              name: "item",
              type: "text",
            },
            {
              display: {
                width: "2",
              },
              label: "UNIT Price in INR",
              name: "unitprice",
              type: "text",
            },
            {
              display: {
                width: "2",
              },
              label: "Quantity",
              name: "quantity",
              type: "text",
            },
            {
              conditions: [
                {
                  rule: "({_data->proposal_detail[i]->unitprice||null} != null) AND ({_data->proposal_detail[i]->quantity||null} != null)",
                  value:
                    "{{_data->proposal_detail[i]->unitprice} * {_data->proposal_detail[i]->quantity}}",
                },
              ],
              display: {
                width: "2",
              },
              is_dynamic: true,
              label: "Value in INR",
              name: "proposal_value",
              type: "text",
            },
          ],
          label: "phase_1",
          type: "object",
        },
        id: "proposal_detail",
        label: "Proposal Details Section (Click on Add to Enter Items)",
        mode: "input",
        name: "proposal_detail",
        type: "array",
      },
    },
    {
      id: "array_with_summary",
      description: "Array field with summary",
      label: "Array field with summary",
      variables: {
        data: {
          array_with_summary: [
            {
              sno: "1",
              item: "lorem",
              unitprice: "10",
              quantity: "10",
            },
            {
              sno: "2",
              item: "ipsum",
              unitprice: "20",
              quantity: "22",
            },
          ],
        },
      },
      value: {
        display: {
          attributes: {
            "remove-btn": true,
            "btn-color": "primary",
            "add-btn": true,
          },
          block: false,
          classes: ["primary--text", "py-3", "text-button"],
          width: "12",
        },
        summary: {
          fields: [
            {
              display: {
                width: "4",
              },
              processor: "count",
              label: "Items",
              name: "count_items",
              type: "text",
            },
            {
              display: {
                width: "4",
              },
              processor: "sum",
              label: "Quantity",
              name: "sum_quantity",
              field: "quantity",
              type: "text",
            },
            {
              display: {
                width: "2",
              },
              label: "Value in INR",
              processor: "sum",
              field: "proposal_value",
              name: "sum_proposal_value",
              type: "text",
            },
          ],
        },
        field: {
          default_value: "",
          display: {
            width: "10",
          },
          fields: [
            {
              display: {
                width: "1",
              },
              label: "SNo.",
              name: "sno",
              type: "text",
            },
            {
              display: {
                width: "4",
              },
              label: "Item",
              name: "item",
              type: "text",
            },
            {
              display: {
                width: "2",
              },
              label: "UNIT Price in INR",
              name: "unitprice",
              type: "text",
            },
            {
              display: {
                width: "2",
              },
              label: "Quantity",
              name: "quantity",
              type: "text",
            },
            {
              conditions: [
                {
                  rule: "({_data->array_with_summary[i]->unitprice||null} != null) AND ({_data->array_with_summary[i]->quantity||null} != null)",
                  value:
                    "{{_data->array_with_summary[i]->unitprice} * {_data->array_with_summary[i]->quantity}}",
                },
              ],
              display: {
                width: "2",
              },
              is_dynamic: true,
              mode: "input",
              disabled: true,
              label: "Value in INR",
              name: "proposal_value",
              type: "text",
            },
          ],
          label: "phase_1",
          type: "object",
        },
        id: "array_with_summary",
        label: "Proposal Details ",
        mode: "input",
        name: "array_with_summary",
        type: "array",
      },
    },
  ],
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    {
      key: "field",
      mandatory: true,
      description: "The fields in the array",
      affects_display: "yes",
    },
  ],
  attributes: [
    { name: "btn-color", type: "color", value: "primary" },
    {
      name: "remove-btn",
      type: "switch",
      hint: "Display the Remove button",
      value: true,
    },
    {
      name: "add-btn",
      type: "switch",
      hint: "Display the Add button",
      value: true,
    },
  ],
};
