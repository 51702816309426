import { forOwn, omit, get, isEqual, random } from "lodash";
const debug = require("debug")("atman.processors");
const processors = {
  count: () => {
    return (val) => {
      if (!Array.isArray(val)) {
        return 0;
      }
      return val.length;
    };
  },
  to_array: () => {
    return (val) => {
      if (!val) {
        return [];
      }
      if (Array.isArray(val)) {
        return val;
      }
      return [val];
    };
  },
  keys_to_array: () => {
    return (val) => {
      let output = [];
      if (!val) {
        return output;
      }
      forOwn(val, (value) => {
        output.push(value);
      });
      return output;
    };
  },
  sum: (inputs) => {
    const field = inputs && inputs.field;
    if (!field) {
      return () => {
        return 0;
      };
    }
    const reducer = (accumulator, item) => {
      let itemValue = item[field] * 1;
      if (isNaN(itemValue)) {
        itemValue = 0;
      }
      accumulator = accumulator + itemValue;
      return accumulator;
    };
    return (val) => {
      if (!Array.isArray(val)) {
        return 0;
      }
      return val.reduce(reducer, 0);
    };
  },
  field: (inputs) => {
    const field = inputs && inputs.field;
    if (!field) {
      return () => {
        return;
      };
    }
    return (val) => {
      if (typeof val != "object") {
        return;
      }
      return get(val, field);
    };
  },
  random_id: () => {
    return (val) => {
      if (!Array.isArray(val)) {
        return;
      }
      let index = random(val.length - 1);
      return get(val, [index]);
    };
  },
  omit: (inputs) => {
    const fields = inputs && inputs.fields;
    if (!fields || !fields.length) {
      return () => {
        return;
      };
    }
    return (val) => {
      if (typeof val != "object") {
        return;
      }
      return omit(val, fields);
    };
  },
  greater_than: (inputs) => {
    const count = inputs && inputs.count;
    if (isNaN(count)) {
      return () => {
        return;
      };
    }
    return (val) => {
      if (isNaN(val)) {
        return;
      }
      return val > count;
    };
  },
  equal: (inputs) => {
    const target = inputs && inputs.target;
    return (val) => {
      return isEqual(target, val);
    };
  },
  filter: (inputs) => {
    const filterFn = inputs && inputs.filter;
    let dynamicFunction;
    if (typeof filterFn !== "string") {
      console.error("Ignoring input to processor: filter");
      dynamicFunction = () => true;
    }
    try {
      dynamicFunction = new Function("data", filterFn);
      debug(`Dynamic function created successfully`);
    } catch (e) {
      console.error("Ignoring input to processor: filter");
      dynamicFunction = () => true;
    }
    return (val) => {
      if (!Array.isArray(val)) {
        console.error(`Invalid input to processor filter`);
        return;
      }
      debug(`Before filtering`, val);
      const result = val.filter(dynamicFunction);
      debug(`After filtering`, result);
      return result;
    };
  },
};

export default processors;
