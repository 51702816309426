<template>
  <div v-if="isDisplayMode">
    <FieldLabel
      v-if="!isBreakdown"
      :definition="value"
      mode="display"
      :label="label"
      :attributes="localLabelAttributes"
    >
      <div class="display:block;">
        <div class="d-flex">
          <span
            aut-total-original-value
            v-if="showDiscount"
            class="behavior_discount pr-2"
          >
            <Currency
              :value="totalFieldWithoutDiscountDefinition"
              :design="false"
            />
          </span>
          <Currency
            aut-total-value
            :value="totalFieldDefinition"
            :design="false"
          />
          <v-tooltip top v-if="showBreakdown">
            <template v-slot:activator="{ on, attrs }">
              &nbsp;<v-icon
                v-on="on"
                v-bind="attrs"
                aut-toggle-breakdown
                @click.stop="toggleLayout"
                small
                class="pr-1"
                >mdi-information</v-icon
              >
            </template>
            Toggle Details
          </v-tooltip>
        </div>
        <div>
          <CountDownClock
            aut-discount-valid-until
            v-if="validUntil && validUntilData"
            v-bind="validUntilData"
          />
        </div>
      </div>
    </FieldLabel>
    <div v-else>
      <Currency aut-unit-value :value="unitFieldDefinition" :design="false" />
      <CostComponents
        :definition="value"
        cost_component="deductions"
        aut-deductions
        mode="display"
        :items="deductions"
        title="Deductions"
      />
      <CostComponents
        :definition="value"
        cost_component="additions"
        aut-additions
        mode="display"
        :items="additions"
        title="Additions"
      />
      <div class="d-flex" v-if="hasComponents">
        <Currency
          aut-total-value
          class="behavior_children_bold"
          :value="totalFieldDefinition"
          :design="false"
        />
        <v-tooltip top v-if="showBreakdown">
          <template v-slot:activator="{ on, attrs }">
            &nbsp;<v-icon
              v-on="on"
              v-bind="attrs"
              aut-toggle-breakdown
              @click.stop="toggleLayout"
              small
              class="pr-1"
              >mdi-information</v-icon
            >
          </template>
          Toggle Details
        </v-tooltip>
      </div>
    </div>
  </div>
  <div v-else class="mb-5">
    <Currency
      aut-unit-value
      v-if="!showBreakdown"
      :value="unitFieldInputDefinition"
      :design="false"
      @change="updateUnitPrice"
    />
    <v-card v-else outlined class="pa-2">
      <v-card-text>
        <v-row dense>
          <v-col cols="12 pa-0">
            <Currency
              aut-unit-value
              :value="unitFieldInputDefinition"
              :design="false"
              @change="updateUnitPrice"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <CostComponents
              :definition="value"
              cost_component="deductions"
              aut-deductions
              mode="input"
              :items="deductions"
              :types="deductionTypes"
              title="Deductions"
              @change="updateDeductions"
            />
          </v-col>
          <v-col cols="12">
            <CostComponents
              :definition="value"
              cost_component="additions"
              mode="input"
              aut-additions
              :items="additions"
              :types="additionTypes"
              title="Additions"
              @change="updateAdditions"
            />
          </v-col>
          <v-col cols="12">
            <Currency
              aut-total-value
              :value="totalFieldDefinition"
              :design="false"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import money from "@/money";
import { get, defaultsDeep } from "lodash";
import { clone } from "@/util";
import { fieldMixin } from "@/components/mixin.js";
import { getTotalValue, getValidUntil } from "./util";
import CostComponents from "./CostComponents.vue";
import definition from "./definition";

const debug = require("debug")("atman.components.fields.cost"); // eslint-disable-line

const noAmount = { amount: 0, currency: "INR" };
const additionTypes = [
  {
    label: "GST",
    type: "igst",
  },
];

const deductionTypes = [];

export default {
  name: "CostField",
  mixins: [fieldMixin],
  components: {
    Currency: () => import("@/components/fields/Currency/Currency.vue"),
    CostComponents,
    CountDownClock: () =>
      import("@/components/fields/CountDownClock/CountDownClock.vue"),
  },
  data() {
    return {
      definition: definition,
      layout: "",
      displayValue: clone(noAmount),
      deductionTypes,
      additionTypes,
      validUntilData: {},
    };
  },
  computed: {
    validUntil() {
      if (!this.showDiscount) {
        return false;
      }
      const result = getValidUntil(this.fieldValue);
      return result;
    },
    hasComponents() {
      const hasComponents =
        this.unitPrice?.amount != this.totalValue?.value?.amount;
      return hasComponents;
    },
    showBreakdown() {
      const config = this.displayAttributes?.show_breakdown == true;
      return config;
    },
    showDiscount() {
      const config = this.displayAttributes?.layout == "discount";
      const hasDifference = money.isMoreThan(
        this.totalValueWithoutDiscount.value,
        this.totalValue.value
      );
      return hasDifference && config;
    },
    totalFieldWithoutDiscountDefinition: {
      get() {
        let result = {
          type: "currency",
          mode: "display",
          label: this.totalValueWithoutDiscount.label,
          name: `${this.value.name}_total_value_currency`,
          value: this.totalValueWithoutDiscount.value,
        };
        debug(`totalFieldWithoutDiscountDefinition`, result);
        return result;
      },
      set(value) {
        debug(`in set`, value);
      },
    },
    totalFieldDefinition: {
      get() {
        let result = {
          type: "currency",
          mode: "display",
          label: this.totalValue.label,
          name: `${this.value.name}_total_value_currency`,
          value: this.totalValue.value,
        };
        debug(`totalFieldDefinition`, result);
        return result;
      },
      set(value) {
        debug(`in set`, value);
      },
    },
    unitFieldInputDefinition: {
      get() {
        const result = Object.assign({}, this.unitFieldDefinition, {
          mode: "input",
          mandatory: this.value?.mandatory,
        });
        debug(`unitFieldInputDefinition`, result);
        return result;
      },
      set(value) {
        debug(`in set of unitFieldInputDefinition`, value);
      },
    },
    unitFieldDefinition: {
      get() {
        let result = {
          type: "currency",
          mode: "display",
          label: "Unit Price",
          name: `${this.value.name}_unit_value_currency`,
          value: this.unitPrice,
          display: {
            width: "12",
          },
        };
        debug(`unitFieldDefinition`, result);
        return result;
      },
      set(value) {
        debug(`in set of unitFieldDefinition`, value);
      },
    },
    isBreakdown() {
      return this.layout == "breakdown";
    },
    unitPrice() {
      let result;
      if (!this.fieldValue) {
        result = null;
      } else if (this.fieldValue?.amount) {
        result = this.fieldValue;
      } else {
        result = this.fieldValue?.unit_price?.value || clone(noAmount);
      }
      debug(`unitPrice`, result);
      return result;
    },
    totalValueWithoutDiscount() {
      const result = {
        label: "",
        value: getTotalValue(this.fieldValue, { ignoreDeductions: true }),
      };
      debug(`totalValueWithoutDiscount`, result);
      return result;
    },
    totalValue() {
      return {
        label: this.isBreakdown || this.mode == "input" ? "Total" : "",
        value: this.displayValue,
      };
    },
    deductions() {
      const deductions = this.fieldValue?.deductions || [];
      debug(`deductions`, deductions);
      return deductions;
    },
    additions() {
      const additions = this.fieldValue?.additions || [];
      debug(`additions`, additions);
      return additions;
    },
    localLabelAttributes() {
      let definition = clone(this.labelAttributes);
      if (this.isBreakdown) {
        definition = defaultsDeep(
          {
            classes: ["behavior_block"],
          },
          definition
        );
      }
      // debug(`localLabelAttributes`, definition);
      return definition;
    },
  },
  mounted() {
    debug(`deductions`, this.deductions);
    this.layout = this.displayAttributes?.layout;
    debug(`mounted of cost field`, this.value);
    this.determineDisplayValue();
  },
  methods: {
    _afterFieldValueUpdate() {
      this.determineDisplayValue();
    },
    async determineDisplayValue() {
      const component = this;

      const rule = component.value?.rule;
      if (rule) {
        switch (rule.operation) {
          case "ITEM_COST": {
            try {
              let costPerItem = await component.$store.dispatch(
                `${component.context}/getFieldValue`,
                { path: component.replaceIndex()(rule.cost_per_item) }
              );

              const quantity = await component.$store.dispatch(
                `${component.context}/getFieldValue`,
                { path: component.replaceIndex()(rule.quantity) }
              );
              if (!costPerItem || !quantity || isNaN(quantity * 1)) {
                debug(
                  `Ignoring inputs: quantity: [${quantity}] and amount`,
                  costPerItem
                );
                component.fieldValue = clone(noAmount);
              } else {
                costPerItem = getTotalValue(costPerItem);
                let outcome = money.multiply(costPerItem, quantity);
                debug(costPerItem, quantity, outcome);
                component.fieldValue = outcome;
              }
            } catch (e) {
              debug(e);
              debugger;
            }
            break;
          }
          case "TOTAL": {
            const data = await component.$store.dispatch(
              `${component.context}/getDataObject`
            );
            debug(`data`, data);
            const field = rule.field;
            const reducer = (accumulator, item) => {
              let itemValue = get(item, field);
              if (!itemValue) {
                return accumulator;
              }
              accumulator = money.add(accumulator, itemValue);
              return accumulator;
            };

            if (!Array.isArray(data)) {
              component.fieldValue = clone(noAmount);
            } else {
              component.fieldValue = data.reduce(reducer, clone(noAmount));
            }
            break;
          }
          default: {
            console.error(`Unsupported rule`);
          }
        }
      }
      const fieldValue = component.fieldValue;
      this.displayValue = getTotalValue(fieldValue);
      debug(`displayValue`, this.displayValue);
      return;
    },
    toggleLayout() {
      this.layout = this.layout == "breakdown" ? "none" : "breakdown";
    },
    updateUnitPrice(value) {
      let fieldValue = this.fieldValue || {};
      if (fieldValue.amount) {
        fieldValue = clone(value);
      } else {
        fieldValue = defaultsDeep(
          {
            unit_price: {
              value: clone(value),
            },
          },
          fieldValue,
          {
            unit_price: {
              label: "Unit Price",
            },
          }
        );
      }
      this.fieldValue = fieldValue;
      debug(`in updateUnitPrice`, this.fieldValue);
    },
    updateAdditions(value) {
      const fieldValue = clone(this.fieldValue || {});
      fieldValue.additions = clone(value);
      this.fieldValue = fieldValue;
      debug(`in after updateAdditions`, this.fieldValue);
    },
    updateDeductions(value) {
      const fieldValue = clone(this.fieldValue || {});
      fieldValue.deductions = clone(value);
      this.fieldValue = fieldValue;
      debug(`in after updateDeductions`, this.fieldValue);
    },
    _derivePropsData() {
      if (!this.isDisplayMode || !this.validUntil) {
        return;
      }
      const validUntilData = clone(this.$props);

      const attributes = {
        limit: "future",
        future_label: "left",
        label: {
          display: {
            mode: "hidden",
          },
        },
      };

      validUntilData.value.display.attributes = defaultsDeep(
        {},
        validUntilData.value.display.attributes?.valid_until || {},
        attributes
      );

      validUntilData.value.name = `${validUntilData.value.name}_valid_until`;
      validUntilData.value.path = `${
        validUntilData?.value?.path || "innerText"
      }_valid_until`;

      delete validUntilData.value.conditions;

      validUntilData.value.value = this.validUntil;
      validUntilData.value.type = "count_down";
      if (this.validUntilData) {
        Object.assign(this.validUntilData, validUntilData);
      } else {
        this.validUntilData = validUntilData;
      }
      debug(this.validUntilData);
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_discount {
  ::v-deep {
    .behaviour_display_value {
      text-decoration: line-through !important;
    }
  }
}
</style>
