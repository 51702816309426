<template>
  <div>
    <v-tooltip top v-if="isInputMode">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="addBtn"
          class="float-right mt-n2"
          :color="btnColor"
          small
          v-bind="attrs"
          v-on="on"
          @click="addArrayItem"
          aut-add-array-item
        >
          Add
          <v-icon right> mdi-plus </v-icon></v-btn
        >
      </template>
      Add an item
    </v-tooltip>
    <ArrayFieldLayout
      :value="value"
      :items="arrayItems"
      :arrayKey="refreshKey"
      @update:items="fieldValue = $event"
    >
      <template v-slot:default="slotProps">
        <ArrayFieldItem
          :value="slotProps.item"
          :aut-array-item="slotProps.index"
          :definition="value"
          :path="path"
          :removeBtn="removeBtn"
          :index="slotProps.index"
          :context="context"
          @remove="removeItem(slotProps.index)"
          @change="updateItem($event, slotProps.index)"
        />
      </template>
    </ArrayFieldLayout>
    <div v-if="summaryFieldDefinition" class="behaviorArraySummary">
      <Field
        :key="summaryFieldDefinitionKey"
        aut-array-summary
        :definition="summaryFieldDefinition"
        :context="context"
      />
    </div>
  </div>
</template>
<script>
import { clone } from "@/util";
import { omit, debounce, defaultsDeep, isEqual } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
import ArrayFieldItem from "./ArrayFieldItem.vue";
import ArrayFieldLayout from "./ArrayFieldLayout.vue";

const debug = require("debug")("atman.components.field.array"); // eslint-disable-line

export default {
  name: "ArrayField",
  components: {
    ArrayFieldItem,
    ArrayFieldLayout,
    Field: () => import("@/components/fields/Field"),
  },
  mixins: [fieldMixin],
  data() {
    return {
      /* This refresh key should ONLY be associated with array addition or removal not 'fieldValue'. If that is done
      the code will go into an infinite loop */
      refreshKey: 1,
      summaryFieldDefinition: null,
      summaryFieldDefinitionKey: 1,
    };
  },
  watch: {
    fieldValue: debounce(
      function () {
        this.updateSummary();
      },
      2000,
      {
        leading: true,
        trailing: true,
      }
    ),
  },
  computed: {
    field() {
      return clone(this.value.field);
    },
    arrayItems() {
      return Array.isArray(this.fieldValue) ? this.fieldValue : [];
    },
    btnColor() {
      let result = this.displayAttributes?.["btn-color"] || "primary";
      debug(`btnColor`, result);
      return result;
    },
    removeBtn() {
      let result = this.displayAttributes?.["remove-btn"];
      debug(`removeBtn`, result);
      return result;
    },
    addBtn() {
      let result = this.displayAttributes?.["add-btn"];
      debug(`addBtn`, result);
      return result;
    },
  },
  mounted() {
    debug("mounted of Array Field", this.value);
    this.getSummaryFieldDefinition();
  },
  methods: {
    updateItem(item, index) {
      debug(`in updateItem`, JSON.stringify(item), index);
      const fieldValue = this.fieldValue;
      const originalItem = fieldValue[index];
      if (isEqual(originalItem, item)) {
        return;
      }
      fieldValue.splice(index, 1, item);
      this.fieldValue = fieldValue;
    },
    updateSummary() {
      const component = this;
      component.summaryFieldDefinition = null;
      this.$nextTick(
        () => {
          debug(`invoking getSummaryFieldDefinition after fieldValue change`);
          component.getSummaryFieldDefinition();
        } /* ,2000 */
      );
    },
    getSummaryFieldDefinition() {
      const component = this;
      let summaryDefinition = clone(component.value.summary);
      if (!summaryDefinition) {
        return;
      }
      debug(`fieldValue is: `, this.fieldValue);
      summaryDefinition.fields = summaryDefinition.fields.map((field) => {
        switch (field.processor) {
          case "count": {
            field.value = component.fieldValue?.length || 0;
            break;
          }
          case "sum": {
            let sum = 0;
            (component.fieldValue || []).forEach((item) => {
              let itemValue = (item[field.field] || 0) * 1;
              if (isNaN(itemValue)) {
                itemValue = 0;
              }
              sum += itemValue;
            });
            field.value = sum;
            break;
          }
        }
        let result = omit(
          defaultsDeep({}, field, {
            mode: "input",
            disabled: true,
          }),
          ["field", "processor"]
        );
        return result;
      });
      let result = Object.assign(
        {
          type: "object",
          name: "__summary_field",
        },
        summaryDefinition
      );
      debug(`in getSummaryFieldDefinition`, JSON.stringify(result));
      this.summaryFieldDefinition = result;
      this.summaryFieldDefinitionKey++;
    },
    getHoverClass(hover) {
      if (this.isInputMode && hover) {
        return "behaviour_hover elevation-1";
      }
      return "";
    },
    addArrayItem() {
      const fieldValue = [
        ...(this.value?.value || []),
        this.field.default_value,
      ];
      this.fieldValue = fieldValue;
      // DON'T MOVE THIS to a watch - see comment above
      this.refreshKey++;
      debug(`this.fieldValue`, this.fieldValue);
    },
    removeItem(index) {
      const fieldValue = this.fieldValue;
      fieldValue.splice(index, 1);
      this.fieldValue = fieldValue;
      // DON'T MOVE THIS to a watch - see comment above
      this.refreshKey++;
      debug(`this.fieldValue`, this.fieldValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border: 1px solid black;
}
</style>
