<template>
  <v-app
    v-mutate.attr="onMutate"
    v-resize="determineVisibility"
    :class="displayNavBar ? 'behavior_appbar_visible' : ''"
  >
    <router-view v-if="isPassThrough"></router-view>
    <div v-else>
      <Header
        v-if="headerVisible"
        :key="`Header -${renderKey}`"
        :left_spacer="spacerSizeLeft"
        :right_spacer="spacerSizeRight"
        :left_panel_visible="leftPanelVisible"
        :right_panel_visible="rightPanelVisible"
        :main_panel_width="mainPanelWidth"
      />
      <Sidebar v-if="sidebarVisible" :key="`Sidebar -${renderKey}`" />
      <Message />
      <Notifications />
      <TermsAndConditions
        v-if="displayTC"
        :display="displayTC"
        @close="displayTC = false"
      />
      <Tutorial />
      <ConfirmationDialog />
      <PageDialog />
      <HistoryDialog />
      <EmailPageDialog />
      <v-main>
        <v-container fluid class="ma-0 pa-0" style="align-items: flex-start">
          <Appbar v-if="displayNavBar" />
          <StaticPage v-if="isStaticPath"></StaticPage>
          <v-row v-else class="pa-2 pt-0">
            <v-col
              class="behavior_spacer_left"
              :cols="spacerSizeLeft"
              v-if="spacerSizeLeft > 0"
            />
            <DynamicPages
              aut-left-panel
              class="px-4 pl-md-4 pr-md-0"
              name="left"
              v-if="leftPanelVisible"
              :width="leftPanelWidth"
              @collapse_panel="expandMain(leftPanelWidth, 'left')"
              :attributes="leftPanelAttributes"
              :editMode="editingDynamicPage"
            >
            </DynamicPages>
            <v-col
              cols="12"
              :sm="mainPanelWidth"
              class="px-0 behavior_home_page"
              aut-main-panel
            >
              <router-view
                class="pageContainer px-1"
                @editing:pin="editingDynamicPage = $event"
              ></router-view>
            </v-col>
            <DynamicPages
              aut-right-panel
              class="px-4 pr-md-4 pl-md-0"
              name="right"
              v-if="rightPanelVisible"
              :width="rightPanelWidth"
              @collapse_panel="expandMain(rightPanelWidth, 'right')"
              :attributes="rightPanelAttributes"
              :editMode="editingDynamicPage"
            />

            <v-col
              class="behavior_spacer_right"
              :cols="spacerSizeRight"
              v-if="spacerSizeRight > 0"
            />
          </v-row>

          <v-row style="align-self: end" v-if="displayFooter">
            <v-col cols="12">
              <Footer />
            </v-col>
          </v-row>
        </v-container>
        <FeedbackButton />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Message from "@/components/pageContent/Message";
import Tutorial from "@/components/pageContent/Tutorial";

import ConfirmationDialog from "@/components/pageContent/ConfirmationDialog.vue";
import TermsAndConditions from "@/components/pageContent/TermsAndConditions.vue";
import Header from "@/components/pageContent/Header/Header.vue";
import StaticPage from "@/components/pageContent/StaticPage/StaticPage.vue";
import Notifications from "@/components/pageContent/Notifications.vue";
import Sidebar from "@/components/pageContent/Sidebar/Sidebar.vue";

import { mapActions, mapGetters } from "vuex";
import { createContext } from "@/util";
import { SET_SKIN } from "@/store";
import { updateTheme } from "@/store/preferences/api.js";

import { isPlainObject, isNumber, toInteger, set } from "lodash";
import Appbar from "@/components/pageContent/Appbar.vue";
import Footer from "@/components/pageContent/Footer.vue";
import FeedbackButton from "@/components/pageContent/FeedbackButton.vue";
import { mapState } from "vuex";
import { isUnAuthenticated } from "@/util";
import { loadCustomisation, triggerCustomEvents } from "@/customisation";
import { LAYOUTS } from "@/constants";

import { SHOW_DIALOG } from "@/constants";

const debug = require("debug")("atman.App");
export default {
  name: "App",
  components: {
    Message,
    FeedbackButton,
    Header,
    Notifications,
    Tutorial,
    Sidebar,
    ConfirmationDialog,
    TermsAndConditions,
    Footer,
    Appbar,
    StaticPage,
    DynamicPages: () =>
      import("@/components/pageContent/DynamicPages/DynamicPages.vue"),
    PageDialog: () => import("@/components/Page/PageDialog"),
    HistoryDialog: () => import("@/components/Page/HistoryDialog"),
    EmailPageDialog: () => import("@/components/Page/EmailPageDialog"),
  },
  data() {
    return {
      context: null,
      displayTC: false,
      renderKey: 1,
      leftPanelWidth: 3,
      rightPanelWidth: 3,
      spacerSizeRight: 0,
      spacerSizeLeft: 0,
      rightPanelVisible: false,
      leftPanelVisible: false,
      mainPanelWidth: 12,
      rightPanelAttributes: {},
      leftPanelAttributes: {},
      editingDynamicPage: false,
    };
  },
  watch: {
    $route(to, from) {
      this.handleRouteChange(to, from);
    },
    "$vuetify.theme.isDark"(isDark) {
      this.changeNoImage(isDark);
    },
  },
  computed: {
    ...mapState("user", ["my_profile"]),
    ...mapState(["skin"]),
    ...mapGetters("preferences", ["activeTheme", "getPreference"]),
    uiComponents() {
      return (this.$route?.query?._ui_components || "").split(",");
    },
    displayNavBar() {
      if (this.isABookPath || isUnAuthenticated(this)) {
        return false;
      }
      let isEnabled = this.isFeatureEnabled("appbar.display");
      if (this.isStaticPath) {
        return isEnabled && this.uiComponents.includes("appbar");
      }
      return isEnabled;
    },
    displayFooter() {
      if (this.isABookPath) {
        return false;
      }
      if (this.isStaticPath) {
        return this.uiComponents.includes("footer");
      }
      return true;
    },
    notPassthrough() {
      return !this.isPassThrough;
    },
    headerVisible() {
      const methodDebug = debug.extend("headerVisible");
      if (this.isABookPath) {
        methodDebug("A book is being read. Will hide the header");
        return false;
      }
      if (this.isStaticPath) {
        return this.uiComponents.includes("header");
      }
      const isUnauthenticated = isUnAuthenticated(this);
      if (
        isUnauthenticated &&
        !this.isFeatureEnabled("header.display_when_unauthenticated")
      ) {
        methodDebug(
          "This is an unauthenticated url and the system is configured to hide the header"
        );
        return false;
      }
      return true;
    },
    sidebarVisible() {
      if (this.isABookPath) {
        return false;
      }
      if (this.isStaticPath) {
        return this.uiComponents.includes("sidebar");
      }
      return this?.skin?.sidebar?.display;
    },
    disableDevtools() {
      return this?.skin?.advanced?.disable_devtools;
    },
    containerClasses() {
      return "";
    },
    isABookPath() {
      const currentPath = this.$route.path;
      let result = /read_book\/.*/.test(currentPath);
      return result;
    },
  },
  mounted: async function () {
    debug("In mounted");
    if (this.notPassthrough) {
      this.determineVisibility();
      this.determineContext();
      await this.fetchSkin({ force: true });
      await this.fetchProfile();
      this.toggleApplicationBehavior();
      this.registerEvents();
      updateTheme();
      loadCustomisation();
      triggerCustomEvents(this.$route);
      if (!this?.$route?.meta?.unauthenticated) {
        this.fetchSecureContent();
      }
    }
    if (this.disableDevtools) {
      document.addEventListener("contextmenu", this.disableRightClick);
      document.addEventListener("keydown", this.disableCtrlShiftI);
      document.addEventListener("keydown", this.disableCtrlShiftJ);
      document.addEventListener("keydown", this.disableCtrlShiftC);
    }
  },
  beforeDestroy() {
    if (this.disableDevtools) {
      document.removeEventListener("contextmenu", this.disableRightClick);
      document.removeEventListener("keydown", this.disableCtrlShiftI);
      document.removeEventListener("keydown", this.disableCtrlShiftJ);
      document.removeEventListener("keydown", this.disableCtrlShiftC);
    }
  },
  methods: {
    ...mapActions("user", ["fetchProfile"]),
    ...mapActions(["fetchSkin", "changeNoImage"]),
    ...mapActions("preferences", ["fetchUserPreferences", "savePreferences"]),
    scrollToTop() {
      // Ref: https://stackoverflow.com/questions/4210798/how-to-scroll-to-top-of-page-with-javascript-jquery
      try {
        // This prevents the page from scrolling down to where it was previously.
        if ("scrollRestoration" in history) {
          history.scrollRestoration = "manual";
        }
        // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
        window.scrollTo(0, 0);
      } catch (e) {
        debug(`could not reset scroll`, e);
      }
    },
    disableRightClick(event) {
      event.preventDefault();
    },
    disableCtrlShiftI(event) {
      if (
        (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "i") ||
        (event.metaKey && event.altKey && event.code === "KeyI")
      ) {
        event.preventDefault();
      }
    },
    disableCtrlShiftJ(event) {
      if (
        (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "j") ||
        (event.metaKey && event.altKey && event.code === "KeyJ")
      ) {
        event.preventDefault();
      }
    },
    disableCtrlShiftC(event) {
      if (
        (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "c") ||
        (event.metaKey && event.altKey && event.code === "KeyC")
      ) {
        event.preventDefault();
      }
    },
    expandMain(val, panel) {
      this.rightPanelVisible = false;
      let originalWidth = this.mainPanelWidth;
      this.mainPanelWidth = originalWidth + val;
      debug(`expandMain invoked from ${panel} with ${val}.`);
    },
    async handleRouteChange(to, from) {
      this.determineVisibility();
      this.determineContext();
      if (
        from?.meta?.unauthenticated === true &&
        to?.meta?.unauthenticated === false
      ) {
        debug(`Moving from unauthenticated to authenticated`);
        await this.fetchSkin({ force: true });
        await this.fetchSecureContent();
        this.showWelcomeMessage();
        this.showWelcomePage();
        this.showApplicationCreationPage();
      } else if (
        from?.meta?.unauthenticated === false &&
        to?.meta?.unauthenticated === true
      ) {
        debug(`Moving from authenticated to unauthenticated`);
        await this.fetchSkin({ force: true });
      }
      triggerCustomEvents(to);
      this.scrollToTop();
    },
    async showTermsAndConditions() {
      debug(`in showTermsAndConditions`);
      if (!this.isFeatureEnabled("terms_and_conditions.enabled")) {
        debug(`Feature is disabled. Aborting`);
        return;
      }
      let tandCFlag = this.getPreference("terms_and_conditions.status");

      debug(`has NOT agreed to t&C`, tandCFlag);
      if (tandCFlag != "agreed") {
        this.displayTC = true;
      }
    },
    async showWelcomePage() {
      debug(`in showWelcomePage`);
      if (!this.isFeatureEnabled("welcome_page.enabled")) {
        debug(`Feature is disabled. Aborting`);
        return;
      }
      let welcomeFlag = this.getPreference("welcome.status");
      debug(`welcome flag in preferences`, welcomeFlag);
      if (welcomeFlag == "displayed") {
        return;
      }
      let welcomeTarget = this.getFeatureValue("welcome_page.target");
      if (!welcomeTarget) {
        console.error(`No welcome target defined.`);
        return;
      }
      this.$store.dispatch(SHOW_DIALOG, { url: welcomeTarget });
      this.markWelcomeAsDisplayed();
    },
    async showApplicationCreationPage() {
      debug(`in showApplicationCreationPage`);
      if (!this.isFeatureEnabled("application_creation.show_on_first_login")) {
        debug(`Feature is disabled. Aborting`);
        return;
      }
      let screenStatus = this.isFeatureEnabled(
        "application_creation.shown",
        false
      );
      debug(`application creation screen Status in preferences`, screenStatus);
      if (screenStatus === true) {
        return;
      }

      this.$router.push("/create_application");
    },
    markWelcomeAsDisplayed() {
      const settings = {};
      set(settings, "welcome.status", "displayed");
      this.savePreferences(settings);
    },
    async showWelcomeMessage() {
      if (!this.isFeatureEnabled("welcome_message.enabled")) {
        debug(`Feature is disabled. Aborting`);
        return;
      }
      await this.fetchProfile();
      let userName = this.my_profile?.id;
      const firstName = this.my_profile?.first_name;
      const lastName = this.my_profile?.last_name;
      if (firstName) {
        userName = firstName;
        if (lastName) {
          userName += ` ${lastName}`;
        }
      }
      if (userName) {
        this.displaySuccessMessage(`Welcome ${userName}`);
      }
    },
    registerEvents() {
      const component = this;
      const unsubscribe = component.$store.subscribe((mutation) => {
        if (mutation.type == SET_SKIN && mutation.payload) {
          debug(`Updating App.vue`);
          this.determineVisibility();
          component.renderKey++;
        }
      });
      component._subscribe(unsubscribe);
    },
    async fetchSecureContent() {
      debug(`Fetching secure content`);
      try {
        await this.fetchUserPreferences();
      } catch (e) {
        console.error(`Could not fetch user preferences`);
      }
      debug(`user preferences have been fetched`);
      this.showTermsAndConditions();
      updateTheme();
    },
    determineContext() {
      this.context = createContext(this.$route.fullPath, {
        ignore_editor: true,
      });
    },
    toggleApplicationBehavior() {
      if (this.isFeatureEnabled("behavior_opacity_on_hover")) {
        this.$el.classList.add("behavior_opacity_on_hover");
      }
      if (this.isFeatureEnabled("behavior_enlarge_on_hover")) {
        this.$el.classList.add("behavior_enlarge_on_hover");
      }
    },
    onMutate() {
      this.$nextTick(() => {
        this.$store.commit(SET_SKIN, null);
      });
    },
    determineVisibility() {
      debug(`determineVisibility`, isUnAuthenticated(this));
      const isAnUnauthenticatedRoute = isUnAuthenticated(this);
      let layout = this.skin?.layout;
      if (isPlainObject(layout)) {
        layout = layout.value;
      }
      this.leftPanelVisible = false;
      this.rightPanelVisible = false;
      if (isAnUnauthenticatedRoute || this.isABookPath) {
        this.determinePanelWidths();
        this.determinePanelAttributes();
        this.renderKey++;
        return;
      }
      const isThreePanelLayout = layout === LAYOUTS.THREE_PANEL;
      const isTwoPanelLayout = layout === LAYOUTS.TWO_PANEL;

      if (isThreePanelLayout) {
        this.leftPanelVisible = true;
        this.rightPanelVisible = true;
      } else if (isTwoPanelLayout) {
        const showPanelOnLeft =
          this.getFeatureValue("layout.two_panel.position", "Left") == "Left";
        this.leftPanelVisible = showPanelOnLeft;
        this.rightPanelVisible = !showPanelOnLeft;
      }
      this.determinePanelWidths();
      this.determinePanelAttributes();
    },
    determinePanelAttributes() {
      this.leftPanelAttributes = {};
      if (this.isSmallScreen) {
        this.leftPanelAttributes.order = 8;
      }
      this.rightPanelAttributes = {};
      if (this.isSmallScreen) {
        this.rightPanelAttributes.order = 9;
      }
    },
    getMainPanelWidth(ignoreSettings = false) {
      if (this.isSmallScreen) {
        return 12;
      }
      const widthFromSettings =
        this.getFeatureValue("layout.main_panel.size") * 1;
      if (!ignoreSettings && isNumber(widthFromSettings)) {
        return widthFromSettings;
      }
      let mainPanelWidth;
      let layout = this.skin?.layout;
      if (isPlainObject(layout)) {
        layout = layout.value;
      }
      layout = layout || LAYOUTS.CLASSIC;
      switch (layout) {
        case LAYOUTS.CLASSIC:
          {
            mainPanelWidth = 12;
          }
          break;
        case LAYOUTS.TWO_PANEL:
          {
            mainPanelWidth = 9;
          }
          break;
        case LAYOUTS.THREE_PANEL:
          {
            mainPanelWidth = 4;
          }
          break;
        default: {
          mainPanelWidth = 12;
        }
      }
      return mainPanelWidth;
    },
    getLeftPanelWidth(ignoreSettings = false) {
      let panelWidth = 0;
      if (!this.leftPanelVisible) {
        return 0;
      }
      if (this.isSmallScreen) {
        return 12;
      }
      panelWidth = 3;
      if (ignoreSettings) {
        return panelWidth;
      }
      const settingsValue = this.getFeatureValue("layout.left_panel.size") * 1;
      if (isNumber(settingsValue)) {
        panelWidth = settingsValue;
      }
      return panelWidth;
    },
    getRightPanelWidth(ignoreSettings = false) {
      let rightPanelWidth = 0;
      if (!this.rightPanelVisible) {
        return 0;
      }
      if (this.isSmallScreen) {
        return 12;
      }
      rightPanelWidth = 3;
      if (ignoreSettings) {
        return rightPanelWidth;
      }
      const settingsValue = this.getFeatureValue("layout.right_panel.size") * 1;
      if (isNumber(settingsValue)) {
        rightPanelWidth = settingsValue;
      }
      return rightPanelWidth;
    },
    determinePanelWidths() {
      this.spacerSizeRight = 0;
      this.spacerSizeLeft = 0;
      this.rightPanelWidth = 0;
      this.leftPanelWidth = 0;
      this.mainPanelWidth = 12;

      // IF this is an unauthenticated route, don't display any other panels
      if (isUnAuthenticated(this) || this.isABookPath) {
        return;
      }

      let mainPanelWidth = this.getMainPanelWidth();
      let rightPanelWidth = this.getRightPanelWidth();
      let leftPanelWidth = this.getLeftPanelWidth();

      let totalWidth = mainPanelWidth + rightPanelWidth + leftPanelWidth;

      debug(
        `[main(${mainPanelWidth}), left(${leftPanelWidth}), right(${rightPanelWidth})].`
      );
      if (totalWidth > 12) {
        console.error(`resetting to defaults`);
        mainPanelWidth = this.getMainPanelWidth(true);
        rightPanelWidth = this.getRightPanelWidth(true);
        leftPanelWidth = this.getLeftPanelWidth(true);
        totalWidth = mainPanelWidth + rightPanelWidth + leftPanelWidth;
        debug(
          `[main(${mainPanelWidth}), left(${leftPanelWidth}), right(${rightPanelWidth})].`
        );
      }
      this.mainPanelWidth = mainPanelWidth;
      this.rightPanelWidth = rightPanelWidth;
      this.leftPanelWidth = leftPanelWidth;

      if (totalWidth === 12) {
        debug(`total width adds up to 12. No spacers necessary`);
        return;
      }
      // Only comes here if there is some gap
      let layout = this.skin?.layout;
      if (isPlainObject(layout)) {
        layout = layout.value;
      }
      if (this.isSmallScreen || layout != LAYOUTS.THREE_PANEL) {
        debug(`is a small screen. no need for spacers`);
        return;
      }
      debug(`total width adds up to less than 12.`);
      const gap = 12 - totalWidth;
      debug(`gap is ${gap}`);
      if (gap % 2 === 0) {
        debug(`gap is even. splitting evenly`);
        this.spacerSizeRight = this.spacerSizeLeft = gap / 2;
      } else {
        debug(`gap is odd. splitting evenly`);
        this.spacerSizeLeft = toInteger(gap % 2);
        this.spacerSizeRight = gap - this.spacerSizeLeft;
      }
      debug(
        `spacers: [left: ${this.spacerSizeLeft}] [right: ${this.spacerSizeRight}]`
      );
    },
  },
};
</script>
<style lang="scss">
/* Styling for scrollbars 
Ref: https://www.digitalocean.com/community/tutorials/css-scrollbars
*/
* {
  scrollbar-width: thin;
  scrollbar-color: grey #000a22;
}

body.printPreview .v-btn.behavior_atman_button {
  display: none !important;
}

body:not(.bookBackground)::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}
body:not(.bookBackground)::-webkit-scrollbar-track {
  background: #000a22; /* color of the tracking area */
}
body:not(.bookBackground)::-webkit-scrollbar-thumb {
  background-color: grey; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #000a22; /* creates padding around scroll thumb */
}

.v-application {
  &.theme--light {
    background-color: #eeeeee !important;
    border-color: #eeeeee !important;
  }
}
</style>
<style lang="scss" scoped>
.pageContainer {
  min-height: 75vh;
}
</style>
<style lang="scss">
.relative {
  position: relative;
}
.smaller {
  transform: scale(0.75);
}
.hidden {
  display: none !important;
}
.hiddenInPage {
  display: none !important;
}
.behavior_centered,
.behaviour_centered {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.behaviour_block_centered,
.behaviour_block_centered-30 {
  padding-left: 30% !important;
  padding-right: 30% !important;
}
.behaviour_block_centered-15 {
  padding-left: 15% !important;
  padding-right: 15% !important;
}
.behaviour_block_centered-10 {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.behaviour_width_fit_content {
  width: fit-content !important;
}

//custom padding classes for > px-16
.px-17 {
  padding-right: 68px !important;
  padding-left: 68px !important;
}
.px-18 {
  padding-right: 72px !important;
  padding-left: 72px !important;
}
.px-19 {
  padding-right: 76px !important;
  padding-left: 76px !important;
}
.px-20 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.px-21 {
  padding-right: 84px !important;
  padding-left: 84px !important;
}
.px-22 {
  padding-right: 88px !important;
  padding-left: 88px !important;
}
.px-23 {
  padding-right: 92px !important;
  padding-left: 92px !important;
}
.px-24 {
  padding-right: 96px !important;
  padding-left: 96px !important;
}

.theme--dark {
  &.primary,
  &.secondary,
  &.accent {
    /*
           This is design standard
           onPrimary, onSecondary and onAccent colors in dark mode should be black
           for better contrast
        */
    color: var(--v-on-primary-base, black) !important;
  }
}

.behavior_action_icon {
  font-size: 16px !important;
}

.behavior_opacity_on_hover {
  .behavior_action_icon {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
}

.behavior_enlarge_on_hover {
  .behavior_action_icon {
    &:hover {
      font-size: 20px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.behavior_home_page {
  min-height: 100%;
}
/* Reduce the white space when app bar is present */
.behavior_appbar_visible {
  ::v-deep {
    .behavior_home_page {
      padding-top: 0px !important;
    }
    .aut-page.container {
      padding-top: 0px !important;
    }
  }
}

::v-deep {
  .theme--light {
    .behaviour_hover {
      background-color: map-get($grey, "lighten-2") !important;
    }
    .behavior_chip {
      &.chip_0 {
        background-color: #757575;
      }
      &.chip_1 {
        background-color: #1b512d;
      }
      &.chip_2 {
        background-color: #43281c;
      }
      &.chip_3 {
        background-color: #05668d;
      }
      &.badge_1 {
        background-color: #533a71;
      }
      &.badge_2 {
        background-color: #d1345b;
      }
      &.badge_3 {
        background-color: #3454d1;
      }
      &.badge_4 {
        background-color: #06ba63;
      }
      &.badge_5 {
        background-color: #e08e45;
      }
    }
  }

  .theme--dark {
    .behaviour_hover {
      background-color: #444 !important;
    }
    .behavior_chip {
      &.chip_0 {
        background-color: #444;
      }
      &.chip_1 {
        background-color: #1b512d;
      }
      &.chip_2 {
        background-color: #99582a;
      }
      &.chip_3 {
        background-color: #006494;
      }
      &.badge_1 {
        background-color: #a44a3f;
      }
      &.badge_2 {
        background-color: #a14a76;
      }
      &.badge_3 {
        background-color: #053c5e;
      }
      &.badge_4 {
        background-color: #4e6e58;
      }
      &.badge_5 {
        background-color: #a44200;
      }
    }
  }

  .behavior_border {
    border: 1px solid map-get($grey, "lighten-1") !important;
  }

  //Controlling display of permission icons - BEGIN
  .behavior_permission_control {
    display: none;
  }
  .behavior_show_permissions {
    .behavior_permission_control {
      display: initial;
    }
  }

  // Ensures field border colors respect the theme
  fieldset {
    border-color: var(--v-primary-base);
  }

  //Controlling display of permission icons - END

  .behaviour_metadata {
    font-size: 0.9em;
    color: map-get($grey, "darken-2");
  }
  .behavior_blurb_text {
    font-size: 1.1rem;
  }

  .behavior_break_all {
    word-break: break-all;
  }

  .behavior_break_word {
    word-break: break-word;
  }

  .behavior_clickable {
    cursor: pointer;
  }

  .behavior_full_width {
    width: 100%;
  }

  .behavior_block {
    display: block !important;
  }
  .behaviour_border_down {
    border-bottom: 1.5px solid var(--v-secondary-lighten3);
  }

  //Providing an option to display secondary as the border color
  .behavior_border_secondary {
    fieldset {
      border-color: var(--v-secondary-base);
    }
  }
  .behavior_smaller_label {
    label.v-label {
      font-size: smaller !important;
    }
  }

  .behavior_single_line {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .behavior_children_bold {
    font-weight: bolder !important;
  }
  .behavior_overflow {
    overflow: visible;
    white-space: normal;
  }
  .behavior_no_bullets ul {
    list-style: none !important;
  }

  .behaviour_scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
