import { defaultsDeep } from "lodash";
import {
  fetchProfile,
  fetchUserMenu,
  signIn,
  signOut,
  fetchUserPermission,
  registration,
  setPassword,
  resetPassword,
} from "./api.js";
import { navigateTo, getFeatureValue } from "@/util.js";

const debug = require("debug")("atman.store.user");
export default {
  namespaced: true,
  state: {
    profile: (() => {
      let userProfile = {};
      if (localStorage.profile) {
        try {
          userProfile = JSON.parse(localStorage.profile);
        } catch {
          debug("profile in localStorage is invalid");
        }
      }
      return userProfile;
    })(),
    my_profile: (() => {
      let myProfile = {};
      if (localStorage.my_profile) {
        try {
          myProfile = JSON.parse(localStorage.my_profile);
        } catch {
          debug("profile in localStorage is invalid");
        }
      }
      return myProfile;
    })(),
    access_control: (() => {
      let accessControl = {};
      if (localStorage.access_control) {
        try {
          accessControl = JSON.parse(localStorage.access_control);
        } catch {
          debug("access_control in localStorage is invalid");
        }
      }
      return accessControl;
    })(),
  },
  getters: {
    displaySidebarForRole: (state, getters, rootState) => {
      const configuredValue =
        rootState.skin?.roles?.[state.profile?.default_profile?.role_id || "0"]
          ?.sidebar?.display;
      const result =
        typeof configuredValue == "boolean" ? configuredValue : true;
      debug(
        `displaySidebarForRole ${state.profile?.default_profile?.role_id}`,
        configuredValue,
        result
      );
      return result;
    },
    canPerformAction: (state) => (action) => {
      if (!action) {
        debug(`In user store. Returning true since no action is passed`);
        return true;
      }

      const bypassRoles = (
        getFeatureValue("permissions.bypass_roles") || ""
      ).split(",");
      const userRole = state?.profile?.default_profile?.role_id || "user";
      if (bypassRoles.includes(userRole)) {
        return true;
      }

      if (!state.access_control || !state.access_control[action]) {
        debug(`Permission not available on user. Returning true`);
        return false;
      }

      debug(
        `Permission available on user. ${
          state.access_control[action]
        }. Returning [${state.access_control[action] === "YES"}]`
      );
      return state.access_control && state.access_control[action] === "YES";
    },
  },
  mutations: {
    setProfile(state, profile) {
      localStorage.profile = JSON.stringify(profile);
      state.profile = profile;
    },
    setMyProfile(state, myProfile) {
      localStorage.myProfile = JSON.stringify(myProfile);
      state.my_profile = myProfile;
    },
    setAccessControl(state, access_control) {
      localStorage.access_control = JSON.stringify(access_control);
      state.access_control = access_control;
    },
  },
  actions: {
    async fetchUserMenu(context, params = {}) {
      const { user } = params || {};
      debug("Action: [fetchUserMenu] invoked");
      const userMenu = await fetchUserMenu(user);
      return userMenu;
    },
    async fetchProfile({ commit }, params = {}) {
      const { user } = params || {};
      debug("Action: [fetchProfile] invoked");
      const userProfile = await fetchProfile(user);
      if (!user) {
        commit("setMyProfile", userProfile);
      }

      const permissions = await fetchUserPermission();
      commit("setAccessControl", permissions);
      return userProfile;
    },
    async login({ commit, dispatch, rootGetters }, credentials) {
      debug("Action: [login] invoked");
      let response;
      try {
        response = await signIn(credentials);
      } catch (e) {
        let errorMessage = typeof e == "string" ? e : "Authentication failed";
        dispatch("error", errorMessage.trim().replace(/\n/, ""), {
          root: true,
        });
        console.error(e);
        throw e;
      }
      commit(
        "setProfile",
        defaultsDeep(
          {
            id: credentials.username,
          },
          response.data
        )
      );
      if (response.access_control) {
        commit("setAccessControl", response.access_control);
      }
      const defaultPage = rootGetters.defaultPage(
        response?.data?.default_profile?.role_id
      );
      debug(`defaultPage for user`, defaultPage);

      let redirectInURL = window.vue.$route?.query?.redirect;
      if (!redirectInURL || redirectInURL == "/") {
        redirectInURL = defaultPage;
      }
      const pathToRedirectTo = response?.action?.url || redirectInURL || "/";
      navigateTo(window.vue.$router, pathToRedirectTo);
    },
    async register({ dispatch }, credentials) {
      debug("Action: [register] invoked", credentials);
      try {
        await registration(credentials);
        return;
      } catch (e) {
        let errorMessage = typeof e == "string" ? e : "Registration failed";
        debug(`Caught error`, e, errorMessage);
        dispatch("error", errorMessage.trim().replace(/\n/, ""), {
          root: true,
        });
        console.error(e);
        throw e;
      }
    },
    resetPassword(context, credentials) {
      debug("Action: [resetPassword] invoked", credentials);
      return resetPassword(credentials);
    },
    setPassword(context, credentials) {
      debug("Action: [setPassword] invoked", credentials);
      return setPassword(credentials);
    },
    logout({ commit }) {
      debug("Action: [logout] invoked");
      try {
        signOut();
      } catch (e) {
        debug("Exception occurred during logout", e);
      } finally {
        commit("logout", {}, { root: true });
        debug("Finally block logout");
        if (localStorage) {
          localStorage.clear();
        }
        commit("setProfile", {});
      }
      return true;
    },
  },
};
