import { defaultsDeep } from "lodash";
import { makeServerCall, uniqueID, isFeatureEnabled } from "@/util.js";

const debug = require("debug")("atman.api.user");

const fetchProfile = async function (user) {
  debug("fetching profile");
  let url = "/api/users/me";
  if (user) {
    url = `/api/users/${user}`;
  }
  return (await makeServerCall({ url })).data;
  // TODO LOGOUT if this request is unsuccessful
};

const fetchUserMenu = async function () {
  debug("fetching user menu");
  let url = "/api/usermenu";
  return (await makeServerCall({ url })).data;
};

const signIn = async function (credentials) {
  debug("sign in", credentials);
  const ui_token = uniqueID();
  const response = await makeServerCall({
    url: "/api/user",
    params: Object.assign({}, { action: "sign_in", ui_token }, credentials),
    type: "post",
    options: {
      noDataNode: true,
      unauthenticated: true,
    },
  });
  let rolePermissions = {};
  if (isFeatureEnabled("permissions.augment_permissions_from_role")) {
    const rolesResponse = await makeServerCall({
      url: `/api/roles/${response.data.default_profile.role_id}`,
      type: "get",
    });
    rolePermissions = rolesResponse.data?._permissions;
    debug(`role permissions`, rolePermissions);
  }
  let result = defaultsDeep(
    {
      data: {
        ui_token,
      },
    },
    response,
    {
      access_control: rolePermissions,
    }
  );
  return result;
};
const fetchUserPermission = async function () {
  let rolePermissions = {};
  if (localStorage.myProfile) {
    const data = JSON.parse(localStorage.myProfile);
    let role = data.default_profile.role_id;

    try {
      const rolesResponse = await makeServerCall({
        url: `/api/roles/${role}`,
        type: "get",
      });
      rolePermissions = rolesResponse.data?._permissions;
      debug("user role permissions", rolePermissions);
    } catch (error) {
      console.error("Failed to fetch user permission:", error);
      throw error;
    }
  }

  return rolePermissions;
};

const registration = async function (credentials) {
  debug("registration", credentials);
  return (
    await makeServerCall({
      url: "/api/users",
      params: Object.assign({}, { action: "register" }, credentials),
      type: "post",
      options: {
        noDataNode: true,
        unauthenticated: true,
      },
    })
  ).data;
};

const setPassword = async function (credentials) {
  debug("reset password", credentials);
  return (
    await makeServerCall({
      url: "/api/user",
      params: Object.assign({}, { action: "set_password" }, credentials),
      type: "post",
      options: {
        noDataNode: true,
        unauthenticated: !!credentials.token,
      },
    })
  ).data;
};

const resetPassword = async function (credentials) {
  debug("reset password", credentials);
  return (
    await makeServerCall({
      url: "/api/user",
      params: Object.assign({}, { action: "reset_password" }, credentials),
      type: "post",
      options: {
        noDataNode: true,
      },
    })
  ).data;
};

const signOut = function () {
  debug("sign out");
  makeServerCall({
    url: "/api/user",
    params: Object.assign({}, { action: "sign_out" }),
    type: "post",
    options: {
      noDataNode: true,
    },
  });
};

export {
  fetchProfile,
  signIn,
  signOut,
  fetchUserPermission,
  registration,
  setPassword,
  resetPassword,
  fetchUserMenu,
};
