<template>
  <div v-if="!isDisplayMode" aut-questionnaire-input>
    <div
      v-for="(questionContent, i) in questions"
      :key="`${i}-${questionContent.id}`"
    >
      <v-card
        class="mb-2"
        v-if="!questionContent.hidden"
        v-bind="questionCardAttributes(questionContent)"
      >
        <v-card-title :class="`mb-0 pb-0 ${LabelAlignment}`">
          <div class="text-subtitle-2">
            <ContentLabel :content="questionContent.category.label" />
          </div>
        </v-card-title>
        <v-card-text class="pt-4" :aut-question="questionContent.id">
          <ContentLabel
            :content="questionContent.question"
            :effectiveSize="effectiveSize"
          />
          <EmbeddedMedia :media="questionContent.media" />
          <v-radio-group
            aut-answer-options
            v-model="answer"
            dense
            class="mt-0"
            row
            @change="handleAnswer(questionContent)"
          >
            <v-radio
              :aut-answer="option.id"
              :disabled="questionContent.disabled"
              aut-questionaire-radio
              v-for="(option, i) in questionContent.answers"
              :key="i"
              :value="option.id"
            >
              <template v-slot:label>
                <div>
                  <ContentLabel :content="option.name" />
                  <EmbeddedMedia :media="option.media" />
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions v-if="allowUndo(questionContent)">
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click="goBack"
                ><v-icon>{{ displayAttributes.undo.icon }}</v-icon></v-btn
              >
            </template>
            {{ displayAttributes.undo.tooltip }}
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </div>
  </div>
  <div v-else aut-questionaire-summary>
    <h2 class="mb-2">Summary</h2>
    <div v-if="diagnosisStyle">
      <v-card elevated outlined aut-questionaire-result class="mb-2">
        <v-card-title class="primary white--text" v-if="summaryTitle">{{
          summaryTitle
        }}</v-card-title>
        <v-card-text class="primary white--text" aut-questionaire-outcome>
          <ContentLabel :content="printOutcome(outcome)" />
        </v-card-text>
        <v-card-text
          class="primary white--text"
          v-if="summaryScore && summaryStats.weightage"
        >
          {{ summaryScore }} : {{ summaryStats.weightage }}
        </v-card-text>
      </v-card>
    </div>
    <div
      v-for="(questionContent, i) in summaryContent"
      :key="`${i}-${questionContent.id}`"
    >
      <v-card
        class="mb-2"
        :aut-question="questionContent.id"
        v-bind="questionCardAttributes(questionContent)"
      >
        <v-card-title class="mb-0 pb-0">
          <p class="my-0 py-0" aut-question-description>
            <!-- <span  class="text-subtitle-2 primary--text"
              >Category:</span
            > -->
            {{ questionContent.category.label }}
          </p>
        </v-card-title>
        <v-card-text>
          <p class="my-0 py-0">
            <span class="text-subtitle-2 primary--text">Question:</span>
            <ContentLabel
              :content="questionContent.question"
              :effectiveSize="effectiveSize"
            />
          </p>
          <p class="my-0 py-0">
            <span
              :aut-answer="questionContent.answer.id"
              class="text-subtitle-2 primary--text"
              >Answer:</span
            >
            <ContentLabel :content="questionContent.answer.name" />
          </p>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="!diagnosisStyle">
      <v-card elevated outlined aut-questionaire-result>
        <v-card-title class="primary white--text" v-if="summaryTitle">{{
          summaryTitle
        }}</v-card-title>
        <v-card-text class="primary white--text" aut-questionaire-outcome>
          <ContentLabel :content="printOutcome(outcome)" />
        </v-card-text>
        <v-card-text
          class="primary white--text"
          v-if="summaryScore && summaryStats.weightage"
        >
          {{ summaryScore }} : {{ summaryStats.weightage }}
        </v-card-text>
      </v-card>
    </div>
    <div v-if="value.mode == 'input' && displayAttributes.redo.display">
      <v-btn block aut-reset-questionaire @click="resetQuestionaire">{{
        displayAttributes.redo.text
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { Engine } from "json-rules-engine";
import { get, set, forOwn, last, isNumber, isPlainObject } from "lodash";
import { clone } from "@/util";
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
import EmbeddedMedia from "./EmbeddedMedia.vue";
import ContentLabel from "./ContentLabel.vue";
import { SHOW_DIALOG } from "@/constants";

const debug = require("debug")("atman.components.fields.questionaire"); // eslint-disable-line

const getQuestions = (currentEvent) => {
  let questions = currentEvent?.params?.id;
  if (typeof questions == "string") {
    questions = [questions];
  }
  return questions;
};

export default {
  name: "QuestionaireField",
  mixins: [fieldMixin],
  data() {
    return {
      stack: [],
      events: [],
      answer: null,
      questions: [],
      definition,
    };
  },
  components: {
    EmbeddedMedia,
    ContentLabel,
  },

  computed: {
    LabelAlignment() {
      const result = this?.displayAttributes?.label_alignment;
      debug(`LabelAlignment: ${result}`);
      return result;
    },
    effectiveSize() {
      const result = this?.displayAttributes?.behavior_size;
      debug(`effectiveSize: ${result}`);
      return result;
    },
    summaryTitle() {
      return this?.value?.definition?.summary?.title;
    },
    outcome() {
      return this.fieldValue?.outcome || {};
    },
    diagnosisStyle() {
      const result = this?.displayAttributes?.diagnosis_style;
      debug(`diagnosis_style`, result);
      return result;
    },
    summaryScore() {
      return this?.value?.definition?.summary?.score_title;
    },

    summaryStats() {
      const result = {};
      (this.summaryContent || []).reduce((accumulator, item) => {
        // debug(`accumulator`, accumulator, `item`,item);
        accumulator[item.category.id] = accumulator[item.category.id] || {};

        // Will contain the total number of questions answered
        accumulator[item.category.id]["all"] =
          (accumulator[item.category.id]["all"] || 0) + 1;

        // Get the weightage associated with an answer
        let weightage = item.answer.weightage || 0;

        // Aggregate the weightage by category
        accumulator[item.category.id].weightage =
          (accumulator[item.category.id].weightage || 0) + weightage;

        // Aggregate the overall weightage
        accumulator.weightage = (accumulator.weightage || 0) + weightage;

        // Aggregate the answers by ID
        let answerValue = item.answer.id;
        accumulator[item.category.id][answerValue] =
          (accumulator[item.category.id][answerValue] || 0) + 1;

        return accumulator;
      }, result);
      debug("summaryStats", result);
      return result;
    },
    summaryContent() {
      let result = [];
      forOwn(this.fieldValue?.responses || {}, (answerID, questionID) => {
        const questionContent = this.questions.find(
          ({ id }) => id == questionID
        );
        if (!questionContent) {
          return;
        }
        const answerContent = questionContent.answers.find(
          ({ id }) => id == answerID
        );
        if (!answerContent) {
          return;
        }
        result.push({
          category: questionContent.category,
          question: questionContent.question,
          id: questionContent.id,
          answer: answerContent,
        });
      });
      debug(`summaryContent`, result);
      return result;
    },
  },
  mounted() {
    debug(`fieldValue`, this.value);
    this.prepare();
  },
  methods: {
    printOutcome(outcome) {
      if (isPlainObject(outcome)) {
        if (outcome.label) {
          return outcome.label;
        }
        let response = "";
        forOwn(outcome, (value) => {
          response += value;
        });
        return response;
      }
      if (typeof outcome == "string") {
        return outcome;
      }
    },
    allowUndo(question) {
      const methodDebug = debug.extend("allowUndo");
      methodDebug(`checking allowUndo for question`, question);
      const component = this;

      // Don't show if there is nothing to go back to
      if (component.stack.length <= 1) {
        methodDebug(`Not enough elements in the stack, returning false`);
        return false;
      }
      // IF the question explicitly does not allow undo, don't show
      if (question.undo === false) {
        methodDebug(`question is configured to explicitly not show undo`);
        return false;
      }
      if (question.disabled === true) {
        methodDebug(`Disabled question didn't show undo, returning false`);
        return false;
      }
      // Get the configured value of the 'undo' attribute
      const configValue = !!component.displayAttributes?.undo?.display;
      methodDebug(`value of 'undo' for this questionnaire`, configValue);
      // Get the event being processed
      const { event: currentEvent } = last(component.stack);
      methodDebug(`Current event being processed is`, currentEvent);
      // Get the questions in the current event
      const questions = getQuestions(currentEvent);
      methodDebug(`questions being displayed in this event`, questions);
      if (!questions || !questions.length) {
        return false;
      }
      // IF a single question is being displayed, return the config value
      if (questions.length === 1) {
        methodDebug(`A single question is being displayed`);
        return configValue;
      }
      // IF more than one question is being displayed, hide the undo for all but the last question
      const lastQuestion = last(questions);
      methodDebug(`More than one question is being displayed`, lastQuestion);
      return lastQuestion !== question.id ? false : configValue;
    },
    goBack() {
      const component = this;
      const { event: currentEvent } = component.stack.pop();
      debug("currentEvent", currentEvent);
      const displayedQuestions = getQuestions(currentEvent);
      displayedQuestions.forEach((questionID) => {
        const question = component.questions.find(({ id }) => id == questionID);
        if (!question) {
          return;
        }
        component.hideQuestion(question);
        //console.log(question);
      });

      const { event: lastEvent } = last(component.stack);
      const lastquestions = getQuestions(lastEvent);
      lastquestions.forEach((questionID) => {
        const question = component.questions.find(({ id }) => id == questionID);
        if (!question) {
          return;
        }

        if (!question.disabled) {
          return;
        }

        component.enableQuestion(question);
      });

      const { event, fieldValue } = component.stack.pop();
      component.fieldValue = clone(fieldValue);
      component.processEvent(event);
    },
    questionCardAttributes(questionContent) {
      const category =
        this?.value?.definition?.categories?.[questionContent?.category?.id];
      const result = category?.display?.attributes || {};
      debug(`questionCardAttributes`, questionContent, category, result);
      return result;
    },
    prepare() {
      let events = [];
      let questions = [];
      const categories = this.value?.definition?.categories || {};
      forOwn(categories, (categoryContent, categoryId) => {
        const categoryEvents = categoryContent.events;
        forOwn(categoryEvents, (eventContent, id) => {
          const event = clone(eventContent);
          event.category = {
            label: categoryContent.label,
            id: categoryId,
          };
          event.id = id;
          events.push(event);
          if (event.type == "display_question") {
            questions.push(Object.assign({ id }, event, { hidden: true }));
          }
        });
      });
      forOwn(this.value.definition.events, (eventContent, id) => {
        const event = clone(eventContent);
        event.id = id;
        events.push(event);
      });
      this.events = clone(events);
      this.questions = clone(questions);
      debug(`events`, events);
      debug(`questions`, questions);
      if (this.value.mode == "input") {
        this.fieldValue = {};
        this.processEvent(clone(this.value.definition.start));
      }
    },
    resetQuestionaire() {
      this.mode = "input";
      this.events = [];
      this.answer = null;
      this.questions = [];
      this.$nextTick(() => {
        this.prepare();
      });
    },
    displaySummary() {
      this.mode = "display";
    },
    async processEvent(event) {
      const component = this;
      if (!event) {
        component.displaySummary();
        return true;
      }
      component.stack.push({
        fieldValue: clone(component.fieldValue),
        event: clone(event),
      });

      debug(`event being processed`, event);
      switch (event.type) {
        case "trigger_event": {
          const ids = event?.params?.id;
          if (Array.isArray(ids)) {
            while (ids.length) {
              let id = ids.shift();
              component.triggerEvent(id);
            }
          } else if (typeof ids == "string") {
            debug(`triggering event`, ids);
            component.triggerEvent(ids);
          }
          break;
        }
        case "display_message": {
          debug(`displaying message`, event.params.id);
          const message = event.params.message;
          component.displayInfoMessage(message);
          break;
        }
        case "navigation": {
          debug(`navigating`, event.params.id);
          const url = event.params.url;
          component.$router.push({ url });
          break;
        }
        case "dialog": {
          debug(`displaying a dialog`, event.params.id);
          const url = event.params.url;
          component.$store.dispatch(SHOW_DIALOG, { url });
          break;
        }

        case "assign": {
          debug(`assigning value`, event.params.target);
          const params = event.params;
          const fieldValue = clone(component.fieldValue);
          set(fieldValue, params.target, params.value);
          component.fieldValue = clone(fieldValue);
          debug(
            `assigning to target [${params.target}] value:`,
            params.value,
            `Updated fieldValue`,
            component.fieldValue
          );
          break;
        }

        case "update": {
          debug(`updating a value`, event.params.target);
          const params = event.params;
          const value = params.value;

          const fieldValue = clone(component.fieldValue);
          let originalValue = get(fieldValue, params.target);
          let newValue;
          if (typeof value == "string") {
            originalValue = originalValue || "";
            newValue = `${originalValue}${value}`;
          } else if (isNumber(value)) {
            originalValue = originalValue || 0;
            newValue = originalValue + value;
          } else if (isPlainObject(value)) {
            originalValue = originalValue || {};
            newValue = Object.assign({}, originalValue, value);
          }
          set(fieldValue, params.target, newValue);
          component.fieldValue = clone(fieldValue);
          debug(
            `assigning to target [${params.target}] value:`,
            newValue,
            `Updated fieldValue`,
            component.fieldValue
          );
          break;
        }
      }
    },
    async triggerEvent(eventID) {
      debug(`triggerEvent`, eventID);
      const component = this;
      const eventDetails = this.events.find((event) => event.id == eventID);
      if (!eventDetails) {
        return;
      }
      switch (eventDetails.type) {
        case "display_question": {
          const nextQuestionID = eventDetails.id;
          component.showQuestion(nextQuestionID);
          break;
        }
        case "display_summary": {
          debug(`In display Summary`, eventDetails);
          const events = await component.processRules(eventDetails.rules);
          await this.processEvents([...events]);
          this.displaySummary();
          break;
        }
        default: {
          debug(`In default`, eventDetails);
        }
      }
    },
    async processEvents(events) {
      if (events.length == 0) {
        return;
      }
      const component = this;
      let event = events.shift();
      await component.processEvent(clone(event));
      if (event.params?._is_exclusive) {
        return;
      }
      component.processEvents([...events]);
    },
    async processRules(rules) {
      const component = this;
      const inputs = {
        questionnaire: component.fieldValue?.responses || {},
        summary: component.summaryContent,
        stats: component.summaryStats,
      };
      debug(`Inputs`, inputs);
      const engine = new Engine(rules);
      const results = await engine.run(inputs);
      return results.events || [];
    },
    async handleAnswer(answeredQuestion) {
      debug(`in handleAnswer`, answeredQuestion, this.answer);

      const answerContent = answeredQuestion.answers.find(
        (answerContent) => answerContent.id == this.answer
      );

      const fieldValue = clone(this.fieldValue || {});
      fieldValue.responses = fieldValue.responses || {};
      fieldValue.responses[answeredQuestion.id] = this.answer;
      this.fieldValue = fieldValue;

      this.answer = null;

      if (this.displayAttributes?.answered_questions == "hide") {
        this.hideQuestion(answeredQuestion);
      } else {
        this.disableQuestion(answeredQuestion);
      }
      let events = [];

      // IF the answer has some rules, process them and handle any events
      if (answerContent.rules) {
        debug(`answer has rules, processing them`, answerContent.rules);
        events = await this.processRules(answerContent.rules);
        if (events.length) {
          debug(`events returned from rules`, events);
          return this.processEvents([...events]);
        }
      }

      // IF the answer has a fallback next, process those events
      events = answerContent.next || [];
      if (events.length) {
        debug(`answer has next events, processing them`, events);
        return this.processEvents([...events]);
      }

      // IF the question has some rules, process them and handle any events
      if (answeredQuestion.rules) {
        debug(`question has rules, processing them`, answeredQuestion.rules);
        events = await this.processRules(answeredQuestion.rules);
        if (events.length) {
          debug(`events returned from rules`, events);
          return this.processEvents([...events]);
        }
      }

      // IF the question a fallback event, process those events
      events = answeredQuestion.next || [];
      if (events.length) {
        debug(`question has next events, processing them`, events);
        return this.processEvents([...events]);
      }
    },
    showQuestion(questionID) {
      debug(`showQuestion`, questionID);
      const question = this.questions.find((questionContent) => {
        return questionContent.id == questionID;
      });
      if (!question) {
        console.error(`Question [${questionID}] is missing`);
        return;
      }
      question.hidden = false;
      debug(`question`, question);
    },
    disableQuestion(question) {
      question.disabled = true;
    },
    enableQuestion(question) {
      question.disabled = false;
    },
    hideQuestion(question) {
      question.hidden = true;
    },
  },
};
</script>
