import {
  solo,
  shaped,
  hint,
  persistent_hint,
  single_line,
  solo_inverted,
  placeholder,
  persistent_placeholder,
  prepend_icon,
  prepend_outer_icon,
  background_color,
  clearable,
  clear_icon,
  validate_on_blur,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  color,
  border_color,
  autofocus,
  append_icon,
  append_outer_icon,
  counter,
  no_value_text,
  no_value_behavior,
  behavior_centered,
  behavior_size,
} from "@/components/fields/attributes.js";
import { defaultsDeep } from "lodash";

const debug = require("debug")("atman.components.text"); // eslint-disable-line

export default {
  label: "Text",
  type: "text",
  description: "Displays a text input field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "disabled",
    "mode",
    "display",
    "block",
    "value",
  ],
  searchField: (col, data = {}) => {
    let result = defaultsDeep(
      {
        mode: "input",
        label: col.label,
        display: {
          classes: ["pt-1"],
          width: "3",
          attributes: {
            clearable: true,
          },
        },
      },
      col
    );
    const existingVal = data?.[col.name];
    if (existingVal) {
      result.value = existingVal;
    }
    debug(`Input Text, search Field`, result);
    return result;
  },
  templates: [
    {
      id: "confirmation_mode",
      description: `Use display attribute <code>"_atman_text_mode"="confirmation"</code> to add confirm value input`,
      label: "Confirmation mode",
      value: {
        name: "confirmation_mode",
        label: "Value",
        type: "text",
        mode: "input",
        display: {
          attributes: {
            _atman_text_mode: "confirmation",
          },
        },
      },
    },
    {
      id: "default",
      description: "The default Text Field",
      label: "Default",
      value: {
        name: "default",
        label: "Title",
        type: "text",
      },
    },
    {
      id: "81bb96ae-efbf-5cf0-87d6-9caf02bf731b",
      label: "Display with Label hidden",
      keyword: ["tutorial"],
      description:
        "Hide the label by adding display: false as a label attribute",
      variables: {
        data: {
          "81bb96ae-efbf-5cf0-87d6-9caf02bf731b": "Lorem Ipsum",
        },
      },
      value: {
        display: {
          attributes: {
            label: {
              classes: ["behavior_block", "font-weight-bold"],
              display: {
                mode: "hidden",
              },
            },
            type: "number",
          },
        },
        label: "STD Code",
        mandatory: false,
        mode: "display",
        name: "81bb96ae-efbf-5cf0-87d6-9caf02bf731b",
        type: "text",
      },
    },
    {
      id: "text_as_chip",
      label: "Text as Chip",
      keyword: ["tutorial"],
      description: "Display text field in chip format",
      variables: {
        data: {
          text_as_chip: "Lorem Ipsum",
        },
      },
      value: {
        display: {
          attributes: {
            display_as_chip: {
              classes: ["pa-2"],
              enabled: true,
              chip_background: "chip_1",
            },
            type: "number",
          },
        },
        label: "",
        mandatory: false,
        mode: "display",
        name: "text_as_chip",
        type: "text",
      },
    },
    {
      id: "random_chip_color",
      label: "Text as Chip (random color)",
      keyword: ["tutorial"],
      description:
        "Display text field in chip format (random background color)",
      variables: {
        data: {
          random_chip_color: "Text as Chip",
        },
      },
      value: {
        display: {
          attributes: {
            display_as_chip: {
              enabled: true,
              random_color: true,
            },
            type: "number",
          },
        },
        label: "Chip",
        mandatory: false,
        mode: "display",
        name: "random_chip_color",
        type: "text",
      },
    },
    {
      id: "5d594bc9-5659-540f-a2ab-7127e6536ed1",
      label: "Display a default text when no value is present",
      keyword: ["tutorial"],
      variables: {
        data: {
          "5d594bc9-5659-540f-a2ab-7127e6536ed1": null,
        },
      },
      value: {
        display: {
          attributes: {
            no_value: {
              text: "Not Available",
            },
            label: {
              classes: ["behavior_block", "font-weight-bold"],
              display: {
                mode: "hidden",
              },
            },
            type: "number",
          },
        },
        label: "STD Code",
        mandatory: false,
        mode: "display",
        name: "5d594bc9-5659-540f-a2ab-7127e6536ed1",
        type: "text",
      },
    },
    {
      id: "display",
      description:
        'Use <code>"mode":"display"</code>  to display a label instead.',
      label: "Display Mode",
      value: {
        name: "display",
        mode: "display",
        label: "Name",
        value: "Freeman",
        type: "text",
      },
    },
    {
      id: "clamped",
      label: "Clamped Mode",
      description: "In clamped mode, the content is truncated to a few lines",
      variables: {
        data: {
          clamped:
            "Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem. Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur. Non occaecat exercitation enim minim amet. Qui magna enim cillum amet ea exercitation nisi qui nisi. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        name: "clamped",
        mode: "display",
        label: "Description",
        type: "text",
        display: {
          attributes: {
            clamped: {
              size: 100,
              icon: "mdi-chevron-down",
            },
          },
        },
      },
    },
    {
      id: "hidden_label",
      description: "Text field with hidden label",
      label: "Hidden Label",
      value: {
        name: "hidden_label",
        label: {
          text: "Title",
          display: false,
        },
        type: "text",
      },
    },
    {
      id: "disabled",
      description:
        "Use the <code>disabled</code> attribute to disable the input.",
      label: "Disabled Mode",
      value: {
        name: "disabled",
        mode: "input",
        label: "Name",
        value: "Freeman",
        type: "text",
        display: {
          attributes: {
            disabled: true,
          },
        },
      },
    },
    {
      id: "conditional_mode",
      keyword: ["tutorial"],
      description: "Conditional Mode",
      label: "Conditional Mode",
      variables: {
        data: {
          status: "Open",
        },
      },
      value: {
        name: "conditional_mode",
        mode: {
          conditions: [
            {
              rule: "{_data->status||Open} != Open",
              value: "ignored",
            },
          ],
        },
        label: "Name",
        type: "text",
      },
    },
    {
      id: "disabled_mandatory",
      keyword: ["tutorial"],
      description: "Disabled attribute takes precedence over mandatory fields",
      label: "Disabled and Mandatory Mode",
      variables: {
        data: {
          disabled_mandatory: "Freeman",
        },
      },
      value: {
        name: "disabled_mandatory",
        mode: "input",
        label: "Name",
        mandatory: true,
        type: "text",
        display: {
          attributes: {
            disabled: true,
          },
        },
      },
    },
    {
      id: "readonly",
      keyword: ["tutorial"],
      description:
        "Use the <code>readonly</code> attribute to make the field readonly.",
      label: "Readonly Mode",
      variables: {
        data: {
          readonly: "Freeman",
        },
      },
      value: {
        name: "readonly",
        mode: "input",
        label: "Name",
        type: "text",
        display: {
          attributes: {
            readonly: true,
          },
        },
      },
    },
    {
      id: "hidden",
      keyword: ["tutorial"],
      description: 'Use <code>"mode":"hidden"</code> to not display the field.',
      label: "Hidden Mode",
      variables: {
        data: {
          readonly: "Freeman",
        },
      },
      value: {
        name: "hidden",
        mode: "hidden",
        label: "Name",
        type: "text",
      },
    },
    {
      id: "masked",
      label: "Masked",
      description: 'Use <code>"masked":"true"</code> to mask the field.',
      variables: {
        data: {
          masked: "Lorem Ipsum",
        },
      },
      value: {
        masked: true,
        name: "masked",
        label: "Title",
        type: "text",
      },
    },
    {
      id: "masked_display",
      keyword: ["tutorial"],
      label: "Masked Display",
      description: "Masked fields remain masked even in display mode",
      variables: {
        data: {
          masked_display: "Lorem Ipsum",
        },
      },
      value: {
        masked: true,
        name: "masked_display",
        label: "Title",
        type: "text",
        mode: "display",
      },
    },

    {
      keyword: ["constraints"],
      id: "mandatory",
      label: "Mandatory",
      description:
        'Use <code>"mandatory":"true"</code> to make the input mandatory.',
      value: {
        name: "mandatory",
        label: "Title",
        mandatory: true,
        type: "text",
      },
    },

    {
      id: "max_length",
      keyword: ["tutorial", "constraints"],
      label: "Input with max length",
      description:
        "Use the <code>maxlength</code> attribute to constrain the input. In this example, the maxlength is set to 10.",
      value: {
        name: "max_length",
        label: "Title",
        type: "text",
        display: {
          attributes: {
            maxlength: 10,
          },
        },
      },
    },
    {
      id: "number",
      label: "Number",
      keyword: ["constraints"],
      description:
        "Use <code>type</code> attribute to control the allowed input. In this example, the type is set to <code>number</code>.",
      value: {
        name: "number",
        label: "Number",
        type: "text",
        display: {
          attributes: {
            type: "number",
          },
        },
      },
    },
    {
      id: "heading1",
      label: "Heading 1",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 1",
      variables: {
        data: {
          heading1: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading1",
        label: "Label",
        type: "text",
        display: {
          attributes: {
            behavior_size: "h1",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading2",
      label: "Heading 2",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 2",
      variables: {
        data: {
          heading2: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading2",
        label: "",
        type: "text",
        display: {
          attributes: {
            behavior_size: "h2",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading3",
      label: "Heading 3",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 3",
      variables: {
        data: {
          heading3: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading3",
        label: "",
        type: "text",
        display: {
          attributes: {
            behavior_size: "h3",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading4",
      label: "Heading 4",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 4",
      variables: {
        data: {
          heading4: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading4",
        label: "",
        type: "text",
        display: {
          attributes: {
            behavior_size: "h4",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading5",
      label: "Heading 5",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 5",
      variables: {
        data: {
          heading5: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading5",
        label: "",
        type: "text",
        display: {
          attributes: {
            behavior_size: "h5",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading6",
      label: "Heading 6",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 6",
      variables: {
        data: {
          heading6: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading6",
        label: "",
        type: "text",
        display: {
          attributes: {
            behavior_size: "h6",
          },
        },
        mode: "display",
      },
    },
    {
      id: "subtitle",
      label: "Subtitle",
      keyword: ["styles", "tutorial"],
      description: `Use <code>"mode":"subtitle-1"</code> or <code>"mode":"subtitle-2"</code> to use subtitle styling. 
      The following is an example of <code>subtitle-1</code>.`,
      variables: {
        data: {
          subtitle: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "subtitle",
        label: "",
        type: "text",
        display: {
          attributes: {
            behavior_size: "subtitle-1",
          },
        },
        mode: "display",
      },
    },
    {
      id: "percentage",
      label: "Percentage",
      keyword: ["constraints"],
      description:
        "Use <code>type</code> attribute to control the allowed input. In this example, the type is set to <code>number</code>.",
      value: {
        name: "percentage",
        label: "Percentage",
        type: "text",
        display: {
          attributes: {
            type: "number",
            min: "0",
            max: "100",
          },
        },
      },
    },
    {
      id: "styling_attribute_1",
      keyword: ["tutorial"],
      label: "Styling",
      category: "styling",
      description:
        'The underlying library used is Vuetify. <br><br>This allows us to use attributes specified on this <a target="_blank" href="https://vuetifyjs.com/en/api/v-text-field/#props">page</a> to style and modify the fields behavior as we want.',
      value: {
        name: "styling_attribute_1",
        label: "Title",
        type: "text",
        display: {
          attributes: {
            outlined: true,
            dense: true,
            "prepend-icon": "mdi-map-marker",
          },
        },
      },
    },
    {
      id: "phone_number",
      label: "Phone Number",
      description: "Text field preconfigured to behave as a phone number",
      value: {
        display: {
          width: "6",
        },
        label: "Phone Number",
        mandatory: false,
        mode: "input",
        name: "phone_number",
        rules: [
          {
            phone_number: true,
          },
        ],
        type: "text",
      },
    },
    {
      id: "id",
      label: "ID",
      description: "Text field preconfigured to behave as an ID",
      value: {
        _system_constraints: {
          _primary_key: true,
        },
        label: "ID",
        mandatory: true,
        mode: "input",
        name: "id",
        type: "text",
      },
    },
    {
      id: "email_address",
      label: "Email Address",
      description: "Text field preconfigured to behave as an email",
      value: {
        display: {
          width: "6",
          attributes: {
            "prepend-inner-icon": "mdi-email",
            prepend_inner_icon_attributes: {
              color: "primary",
            },
          },
        },
        label: "Email Address",
        mandatory: false,
        mode: "input",
        name: "email_address",
        rules: [
          {
            email: true,
          },
        ],
        type: "text",
      },
    },
    {
      id: "variable_function",
      label: "Parent Context Path",
      description: "Example of fetching a parent context path",
      variables: {
        data: {
          variable_function: null,
          _url: "/applications/neurex/patients/patient_1/case_studies/case_study_1",
        },
      },
      value: {
        display: {
          width: "6",
        },
        label: "Path",
        mandatory: false,
        mode: "display",
        value: "{_parent({_data->_url})->{_url}}",
        name: "variable_function",
        type: "text",
      },
    },
    {
      id: "content_conversion",
      label: "Content conversion",
      description: "Example of content being converted",
      variables: {
        data: {
          content_conversion: null,
        },
      },
      value: {
        display: {
          attributes: {
            auto_conversion: ["to_lowercase"],
          },
          width: "6",
        },
        label: "Username",
        mandatory: false,
        mode: "input",
        name: "content_conversion",
        type: "text",
      },
    },
    {
      id: "email_address_delayed",
      label: "Delayed validation",
      description:
        "The field can be configured to delay validation till after the user has finished typing",
      value: {
        display: {
          width: "6",
          attributes: {
            validate_on_blur: true,
          },
        },
        label: "Email Address",
        mandatory: false,
        mode: "input",
        name: "email_address_delayed",
        rules: [
          {
            email: true,
          },
        ],
        type: "text",
      },
    },
  ],
  attributes: [
    solo,
    shaped,
    hint,
    persistent_hint,
    single_line,
    solo_inverted,
    placeholder,
    persistent_placeholder,
    prepend_icon,
    prepend_outer_icon,
    background_color,
    clearable,
    clear_icon,
    autofocus,
    append_icon,
    append_outer_icon,
    validate_on_blur,
    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    dense,
    color,
    border_color,
    counter,
    no_value_text,
    no_value_behavior,
    {
      name: "type",
      type: "select",
      hint: "Type of input",
      options: ["text", "number"],
    },
    {
      name: "_atman_text_mode",
      type: "select",
      hint: "Show a confirmation prompt",
      value: "",
      options: ["default", "confirmation"],
    },
    /* NOTE: constraints are not implemented yet. 
    They are supposed to control the visibility of one attribute based on another's value
    customiseAttribute("min", { constraint: { type: "number" } }),
    customiseAttribute("max", { constraint: { type: "number" } }),
     */
    behavior_centered,
    behavior_size,
  ],
  classes: [],
};
