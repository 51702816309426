import { isChildOf } from "@/util.js";
import { STORE_CONSTS } from "@/components/fields/store.js";
import { isFeatureEnabled } from "@/feature_flags";

// import store from "@/store";
const debug = require("debug")("atman.components:mixin");

export default {
  props: {
    context: {
      type: String,
    },
    default_title: {
      type: String,
    },
  },
  watch: {
    $route() {
      this.handleRouteChange();
    },
  },
  data() {
    return {
      displayTitle: false,
    };
  },
  methods: {
    handleRouteChange() {
      // Does not handle conditions
      const dataURL = this.definition?.apis?.data?.url;
      if (!dataURL) {
        return;
      }
      if (dataURL.indexOf("_route") != -1) {
        this.fetchData();
      }
    },
    determineTitleDisplay() {
      let result = false;
      const isInsideAPageEditor =
        this.$el && isChildOf(this.$el, ".behavior_page_editor");
      const isInsideATab =
        this.$el && isChildOf(this.$el, ".behavior_page_tab");
      debug(`this.isDesignMode`, this.isDesignMode);
      if (this.isDesignMode) {
        result = true;
      } else if (!isInsideAPageEditor && isInsideATab) {
        result = false;
      } else {
        const effectiveTitle =
          this.definition.title || this.default_title || "";
        result = !!effectiveTitle.trim();
      }
      debug(`displayTitle`, result);
      this.displayTitle = result;
    },
    handleEmpty(field) {
      debug(`in handle empty of component Mixin`, field);
    },
    setTimers() {
      const component = this;
      if (!isFeatureEnabled("refresh_interval.enabled")) {
        return false;
      }
      if (component.timersSet) {
        return;
      }
      component.intervals = [];
      const dataFetchInterval =
        component?.definition?.apis?.data?.refresh_interval;
      if (dataFetchInterval) {
        component.intervals.push(
          setInterval(() => {
            component.fetchData();
          }, dataFetchInterval * 1000)
        );
      }
      component.timersSet = true;
    },
    async fetchData() {
      const component = this;

      let data;
      const params = {};

      debug(`getData being invoked with params`, params);
      try {
        data = await component.$store.dispatch(
          `${component.context}/getData`,
          params
        );
        component.$store.commit(
          `${component.context}/${STORE_CONSTS.DATA}`,
          data
        );
        component.$store.commit(
          `${component.context}/${STORE_CONSTS.ORIGINAL_DATA}`,
          data
        );
      } catch (e) {
        // store.dispatch("error", "Lorem ipsum");
        console.error(`fetchData failed`, e);
      } finally {
        component.setTimers();
        component.setupContextWatch(component?.definition?.apis?.data);
      }
      return data;
    },
    setupContextWatch(options = {}) {
      const { type = "get", url } = options || {};
      const component = this;
      const debugKey = `${component.definition?.type}_${
        component.definition?.id ||
        component.definition?.name ||
        "unknown_component"
      }`;
      const methodDebug = debug.extend(`context_update_${debugKey}`);

      if (type != "get") {
        methodDebug(`Url is not get, aborting setup context watch`);
        return;
      }
      if (
        component.contextSubscribed ||
        !component.context ||
        !component.$store.getters[`${component.context}/dynamicText`]
      ) {
        methodDebug(
          `Aborting contextwatch setup since context is already subscribed or store is not ready/available`
        );
        return;
      }
      let contextURL = component.$store.getters[
        `${component.context}/dynamicText`
      ]({ url });
      // Remove parameters
      if (contextURL.indexOf("?") != -1) {
        contextURL = contextURL.substring(0, contextURL.indexOf("?"));
      }
      // Add / if missing
      if (contextURL.indexOf("/") != 0) {
        contextURL = `/${contextURL}`;
      }
      methodDebug(debugKey, `Setting up context update watch for`, contextURL);
      const unsubscribe = component.$store.subscribe((mutation) => {
        if (mutation.type != "CONTEXT_UPDATE") {
          return;
        }
        // First remove all params
        let parentURL = mutation.payload;
        if (parentURL.indexOf("?") != -1) {
          parentURL = parentURL.substring(0, mutation.payload.indexOf("?"));
        }
        // Then remove the context
        parentURL = parentURL.substring(0, parentURL.lastIndexOf("/"));
        methodDebug(
          debugKey,
          `context watch triggered. contextURL: [${contextURL}]. payload: [${mutation.payload}]. parentURL: [${parentURL}]`
        );
        if (
          mutation.payload != `/api${contextURL}` &&
          parentURL != `/api${contextURL}`
        ) {
          methodDebug(debugKey, `Ignoring context update`);
          return;
        }
        methodDebug(debugKey, `triggering fetch/refresh for`, component.$el);
        const isInsideALayout =
          this.$el && isChildOf(this.$el, ".behavior_layout_container");
        if (isInsideALayout) {
          component.$emit("refresh_content");
        } else {
          component.fetchData();
        }
      });
      component._subscribe(unsubscribe);
      component.contextSubscribed = true;
    },
  },
};
