import { set, get, forOwn, isPlainObject } from "lodash";
const debug = require("debug")("feature_flags");
import { allFeatures as settings } from "@/settings.js";

const clone = (input) => JSON.parse(JSON.stringify(input));

const feature_flags = {
  ...settings,
  doc_in_iframe: {
    value: false,
    label: "Displaying word documents",
    hint: ["As an icon", "In an iFrame"],
  },
  public_pages: {
    value: false,
    label: "Public pages",
    hint: ["Not Supported", "Supported"],
  },
  page_title: {
    label: "Support for Page titles",
    value: false,
    hint: ["Not Supported", "Supported"],
  },
  "dynamic_pages.remove_missing_dynamic_pages": {
    label: "What to do when a dynamic page is missing",
    value: true,
    hint: ["Show a skeleton", "Remove the page"],
  },
  stubbed_responses: {
    label: "Support for stubbed responses",
    value: false,
    hint: ["Disable", "Enable"],
  },
  event_scheduler: {
    label: "New event scheduler field",
    value: false,
    hint: ["Disable", "Enable"],
  },
  panel_style: {
    label: "Styles for panel",
    value: false,
    hint: ["Disable", "Enable"],
  },
  behavior_opacity_on_hover: {
    label: "Make Icons more opaque when hovered over",
    value: false,
    hint: ["Disable", "Enable"],
  },
  behavior_enlarge_on_hover: {
    label: "Make Icons larger when hovered over",
    value: false,
    hint: ["Disable", "Enable"],
  },
  bulk_operations: {
    label: "Support bulk operations in lists",
    value: false,
    hint: ["Disable", "Enable"],
  },
  image_width_in_list: {
    label: "Image width in lists",
    value: "50px",
  },
  image_width_in_table: {
    label: "Image width in table column",
    value: "100px",
  },
  image_width_in_card: {
    label: "Image width in list cards",
    value: "100%",
  },
  profile_avatar_size: {
    label: "Avatar size",
    value: "70px",
  },
};

const getFeatureFlags = () => {
  let currentFeatureFlags = {};
  try {
    currentFeatureFlags = JSON.parse(localStorage.feature_flags || "{}");
  } catch (e) {
    debug(`Couldn't parse localStorage.feature_flags`);
  }
  debug(`returning`, currentFeatureFlags);
  // Add defaults
  Object.keys(feature_flags).forEach((key) => {
    currentFeatureFlags[key] =
      typeof currentFeatureFlags[key] != "undefined"
        ? currentFeatureFlags[key]
        : feature_flags[key].value;
  });
  // Remove redundant ones
  Object.keys(currentFeatureFlags).forEach((key) => {
    if (!feature_flags[key]) {
      delete currentFeatureFlags[key];
    }
  });
  debug(`getFeatureFlags`, currentFeatureFlags);
  return currentFeatureFlags;
};

const isFeatureEnabled = (key, defaultValue = false) => {
  let value = getFeatureValue(key);
  if (typeof value == "undefined") {
    value = defaultValue;
  }
  return value === true;
};

const getFeatureValue = (key, defaultValue) => {
  const domainFeatures = window.vue?.$store?.getters?.domainFeatures;
  let effectiveKey = key;
  if (key.includes("custom_settings")) {
    effectiveKey = `${key}.value`;
  }
  const domainFeatureValue = get(domainFeatures, effectiveKey);
  debug(`domain feature value of [${key}]`, domainFeatureValue);
  let result = domainFeatureValue;
  if (typeof result == "undefined") {
    result = getFeatureFlags()[key];
  }
  if (typeof result == "undefined" && typeof defaultValue != "undefined") {
    result = defaultValue;
  }
  return result;
};

const updateFeatureFlag = (key, value) => {
  let persistedValues = getFeatureFlags();
  set(persistedValues, key, value);
  localStorage.feature_flags = JSON.stringify(persistedValues);
};

const convertToNestedObject = (inputObject) => {
  let output = {};
  forOwn(inputObject, (value, key) => {
    if (!isPlainObject(value)) {
      set(output, key, value);
    } else {
      set(output, key, convertToNestedObject(value));
    }
  });
  return output;
};

const convertToFlatObject = (inputObject) => {
  let output = clone(inputObject);
  forOwn(output, (value, key) => {
    if (isPlainObject(value)) {
      value = convertToFlatObject(value);
      forOwn(value, (value2, key2) => {
        output[`${key}.${key2}`] = value2;
      });
      delete output[key];
    }
  });
  return output;
};

const featureFlagsObject = convertToNestedObject(getFeatureFlags());

export {
  featureFlagsObject,
  feature_flags,
  isFeatureEnabled,
  getFeatureFlags,
  updateFeatureFlag,
  getFeatureValue,
  convertToNestedObject,
  convertToFlatObject,
};
